<template>
  <div>
    <CoolLightBox
      :items="album_items"
      :index="album_index"
      @close="album_index = null"
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    >
    </CoolLightBox>

    <b-modal
      ref="add_wallet_modal"
      :title="$t('add_to_wallet')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="add_wallet_error || add_wallet_success ? true : false"
            :variant="add_wallet_error ? 'danger' : 'success'"
            dismissible
            @dismissed="add_wallet_error = add_wallet_success = ''"
          >
            {{ add_wallet_error || add_wallet_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("amount") }}</label>
              <input
                type="number"
                class="form-control"
                :class="{
                  'is-invalid': add_wallet_errors.amount
                }"
                v-model="add_wallet_data.amount"
              />
              <span class="error invalid-feedback d-block">{{
                (add_wallet_errors.amount || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("transaction_alias") }}</label>
              <input
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': add_wallet_errors.transaction_alias
                }"
                v-model="add_wallet_data.transaction_alias"
              />
              <span class="error invalid-feedback d-block">{{
                (add_wallet_errors.transaction_alias || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="add_wallet_disabled"
            @click.prevent="addToWallet"
          >
            {{ $t("add") }}
            <b-spinner small v-if="add_wallet_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="wallet_customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in wallet_table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="wallet_table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="edit_store_modal"
      id="edit_store_modal"
      :title="$t('edit') + ' ' + $t('store_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_store_error || edit_store_success ? true : false"
            :variant="edit_store_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_store_error = edit_store_success = ''"
          >
            {{ edit_store_error || edit_store_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("image") }}</label>
              <div
                class="image_div"
                v-if="edit_store_data_image || edit_store_data.store_logo"
              >
                <img
                  :src="
                    edit_store_data_image ||
                      image_url + '/' + edit_store_data.store_logo
                  "
                  @error="$event.target.src = '/images/placeholder.jpg'"
                />
                <i
                  v-if="edit_store_data_image"
                  class="fa fa-times"
                  @click.prevent="
                    edit_store_data_image = edit_store_data_icon = null
                  "
                ></i>
              </div>
              <b-form-file
                v-if="!edit_store_data_image"
                accept="image/*"
                @change.prevent="onImageChange($event, 'edit_store_data')"
                ref="edit_store_data_icon"
              ></b-form-file>
              <span class="error invalid-feedback d-block">{{
                (edit_store_errors.store_logo_input || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-12">
              <label>{{ $t("store_name") }}</label>
              <input
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': edit_store_errors.store_name
                }"
                v-model="edit_store_data.store_name"
              />
              <span class="error invalid-feedback">{{
                (edit_store_errors.store_name || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("contact_mobile") }}</label>
              <input
                type="number"
                min="0"
                class="form-control ltr"
                v-model="edit_store_data.store_contact_mobile"
                :class="{
                  'is-invalid': edit_store_errors.store_contact_mobile
                }"
              />
              <span class="error invalid-feedback">{{
                (edit_store_errors.store_contact_mobile || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("store_url") }}</label>
              <input
                type="text"
                class="form-control ltr"
                v-model="edit_store_data.store_url"
                :class="{
                  'is-invalid': edit_store_errors.store_url
                }"
              />
              <span class="error invalid-feedback">{{
                (edit_store_errors.store_url || []).join("-")
              }}</span>
            </div>

            <div
              class="input-group"
              style="height:250px;border-radius:0.25rem;overflow:hidden;"
            >
              <div class="col-md-12" style="padding:0;">
                <GmapMap
                  :center="{
                    lat: edit_store_data.lat,
                    lng: edit_store_data.lng
                  }"
                  :zoom="8"
                  map-type-id="terrain"
                  :options="{
                    mapTypeControl: false,
                    streetViewControl: false
                  }"
                  style=" height: 250px"
                >
                  <GmapMarker
                    :position="{
                      lat: edit_store_data.lat,
                      lng: edit_store_data.lng
                    }"
                    :draggable="true"
                    @dragend="updatePosition2"
                  />
                </GmapMap>
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_store_disabled"
            @click.prevent="updateStore"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_store_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <div class="load-content" v-if="!load">
      <b-spinner variant="primary"></b-spinner>
    </div>

    <section class="content-header" v-if="load">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ vendor["name"] }}</h1>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content" v-if="load">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body active>
                <template v-slot:title>
                  <i class="fas fa-pencil-alt mr-2 ml-2"></i> {{ $t("info") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <label>{{ $t("name") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': add_errors.name
                              }"
                              v-model="vendor.name"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.name || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-12">
                            <label>{{ $t("email") }}</label>
                            <input
                              type="text"
                              class="form-control ltr"
                              v-model="vendor.email"
                              :class="{
                                'is-invalid': add_errors.email
                              }"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.email || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("mobile") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control ltr"
                              v-model="vendor.mobile"
                              :class="{
                                'is-invalid': add_errors.mobile
                              }"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.mobile || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("contact_mobile") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control ltr"
                              v-model="vendor.contact_mobile"
                              :class="{
                                'is-invalid': add_errors.contact_mobile
                              }"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.contact_mobile || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-12">
                            <label
                              >{{ $t("commission_per") }} (%)
                              <span
                                style="color:red"
                                v-if="!vendor.commission_per"
                              >
                                -
                                {{
                                  $t("general") + " " + commission + "%"
                                }}</span
                              ></label
                            >
                            <input
                              type="number"
                              min="0"
                              max="100"
                              class="form-control ltr"
                              v-model="vendor.commission_per"
                              :class="{
                                'is-invalid': add_errors.commission_per
                              }"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.commission_per || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-12">
                            <label>{{ $t("national_id") }}</label>
                            <input
                              type="number"
                              min="0"
                              max="100"
                              class="form-control ltr"
                              v-model="vendor.national_id"
                              :class="{
                                'is-invalid': add_errors.national_id
                              }"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.national_id || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-12">
                            <label>{{ $t("national_id_image") }}</label>
                            <div
                              class="image_div"
                              @click="
                                showFullImage(
                                  national_id_image ||
                                    image_url2 + '/' + vendor.national_id_image
                                )
                              "
                              v-if="
                                national_id_image || vendor.national_id_image
                              "
                            >
                              <img
                                :src="
                                  national_id_image ||
                                    image_url2 + '/' + vendor.national_id_image
                                "
                                @error="
                                  $event.target.src = '/images/placeholder.jpg'
                                "
                              />
                              <i
                                v-if="national_id_image"
                                class="fa fa-times"
                                @click.prevent="
                                  national_id_image = national_id_icon = null
                                "
                              ></i>
                            </div>
                            <b-form-file
                              v-if="!national_id_image"
                              accept="image/*"
                              @change.prevent="
                                onImageChange($event, 'national_id')
                              "
                              ref="edit_store_data_icon"
                            ></b-form-file>
                            <span class="error invalid-feedback d-block">{{
                              (add_errors.national_id_image_input || []).join(
                                "-"
                              )
                            }}</span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="updateVendor"
                        >
                          {{ $t("save") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
              <b-tab no-body>
                <template v-slot:title>
                  <i class="fas fa-money-check  mr-2 ml-2"></i>
                  {{ $t("bank_account") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="bank_error || bank_success ? true : false"
                          :variant="bank_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="bank_error = bank_success = ''"
                        >
                          {{ bank_error || bank_success }}
                        </b-alert>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <label>{{ $t("holder_name") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': bank_errors.name
                              }"
                              v-model="bank_data.name"
                            />
                            <span class="error invalid-feedback">{{
                              (bank_errors.name || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-12">
                            <label>{{ $t("bank_name") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="bank_data.bank_name"
                              :class="{
                                'is-invalid': bank_errors.bank_name
                              }"
                            />
                            <span class="error invalid-feedback">{{
                              (bank_errors.bank_name || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-12">
                            <label>{{ $t("iban") }}</label>
                            <input
                              type="text"
                              class="form-control ltr"
                              v-model="bank_data.iban"
                              :class="{
                                'is-invalid': bank_errors.iban
                              }"
                            />
                            <span class="error invalid-feedback">{{
                              (bank_errors.iban || []).join("-")
                            }}</span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="bank_disabled"
                          @click="updateBank"
                        >
                          {{ $t("save") }}
                          <b-spinner small v-if="bank_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
              <b-tab no-body :title="$t('stores')">
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("stores") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <i
                            class="fas fa-cog c_pointer customize_table"
                            :title="$t('customize_table')"
                            @click="customizeTable"
                          ></i>
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th v-if="table_selected_fields.includes('#')">
                              #
                            </th>
                            <th v-if="table_selected_fields.includes('image')">
                              {{ $t("image") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('name')"
                              class="sort"
                              @click="sortTable('store_name')"
                            >
                              {{ $t("name") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              class="sort"
                              @click="sortTable('created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('status')"
                              class="sort"
                              @click="sortTable('status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('actions')"
                            >
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :colspan="table_selected_fields.length"
                              v-if="!load"
                            >
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              :colspan="table_selected_fields.length"
                              class="no_data"
                              v-if="load && stores.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in stores"
                            :key="i"
                            v-show="load"
                          >
                            <td v-if="table_selected_fields.includes('#')">
                              {{ i + 1 }}
                            </td>
                            <td v-if="table_selected_fields.includes('image')">
                              <img
                                :src="image_url + '/' + item.store_logo"
                                @error="
                                  $event.target.src = '/images/placeholder.jpg'
                                "
                              />
                            </td>
                            <td v-if="table_selected_fields.includes('name')">
                              {{ item.store_name }}
                            </td>

                            <td
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              v-html="dateTimeFormat(item.created_at)"
                            ></td>
                            <td v-if="table_selected_fields.includes('status')">
                              <span
                                :class="
                                  'badge badge-' +
                                    (item.status == 'on' ? 'info' : 'danger')
                                "
                                >{{ $t(item.status) }}</span
                              >
                            </td>
                            <td
                              class="actions-group"
                              v-if="table_selected_fields.includes('actions')"
                            >
                              <button
                                v-if="
                                  $can('edit stores') &&
                                    item.status == 'pending'
                                "
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('accept')"
                                @click="acceptStore(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-check" v-else></i>
                              </button>

                              <button
                                v-if="
                                  $can('edit stores') &&
                                    item.status != 'pending'
                                "
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('change_status')"
                                @click="changeStatus(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-exchange-alt" v-else></i>
                              </button>
                              <button
                                v-if="$can('edit stores')"
                                @click="showEditStore(item.id)"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete stores')"
                                @click="deleteStore(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add stores')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i>
                  {{ $t("add") + " " + $t("store") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>

                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <label>{{ $t("image") }}</label>

                            <div class="image_div" v-if="add_data_image">
                              <img :src="add_data_image" />
                              <i
                                class="fa fa-times"
                                @click.prevent="
                                  add_data_image = null;
                                  add_data_icon = null;
                                "
                              ></i>
                            </div>
                            <b-form-file
                              v-else
                              :placeholder="$t('choose_file')"
                              accept="image/*"
                              @change.prevent="
                                onImageChange($event, 'add_data')
                              "
                              ref="add_data_icon"
                            ></b-form-file>
                            <span class="error invalid-feedback d-block">{{
                              (add_errors.store_logo_input || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-12">
                            <label>{{ $t("store_name") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': add_errors.store_name
                              }"
                              v-model="add_data.store_name"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.store_name || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-6">
                            <label>{{ $t("contact_mobile") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control ltr"
                              v-model="add_data.store_contact_mobile"
                              :class="{
                                'is-invalid': add_errors.store_contact_mobile
                              }"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.store_contact_mobile || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("store_url") }}</label>
                            <input
                              type="text"
                              class="form-control ltr"
                              v-model="add_data.store_url"
                              :class="{
                                'is-invalid': add_errors.store_url
                              }"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.store_url || []).join("-")
                            }}</span>
                          </div>

                          <div
                            class="input-group"
                            style="height:250px;border-radius:0.25rem;overflow:hidden;"
                          >
                            <div class="col-md-12" style="padding:0;">
                              <GmapMap
                                :center="{
                                  lat: add_data.lat,
                                  lng: add_data.lng
                                }"
                                :zoom="8"
                                map-type-id="terrain"
                                :options="{
                                  mapTypeControl: false,
                                  streetViewControl: false
                                }"
                                style=" height: 250px"
                              >
                                <GmapMarker
                                  :position="{
                                    lat: add_data.lat,
                                    lng: add_data.lng
                                  }"
                                  :draggable="true"
                                  @dragend="updatePosition"
                                />
                              </GmapMap>
                            </div>
                            <div
                              class="get_current_location"
                              v-tooltip="$t('my_location')"
                              @click="getLocation"
                            >
                              <i class="fas fa-location-arrow"></i>
                            </div>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addStore"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
              <b-tab no-body :title="$t('wallet')">
                <template v-slot:title>
                  <i class="fas fa-wallet ml-2 mr-2"></i> {{ $t("wallet") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      {{ $t("balance") }}: {{ balance }} {{ $t("sar") }}
                      <button
                        class="btn btn-success btn-sm mr-2 ml-2"
                        @click="showAddToWallet"
                      >
                        <i class="fa fa-plus mr-1 ml-1"></i
                        >{{ $t("add_to_wallet") }}
                      </button>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <i
                            class="fas fa-cog c_pointer customize_table"
                            :title="$t('customize_table')"
                            @click="wallet_customizeTable"
                          ></i>
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="wallet_search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="wallet_search_disabled"
                              @click.prevent="wallet_searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="wallet_search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th
                              v-if="wallet_table_selected_fields.includes('#')"
                            >
                              #
                            </th>
                            <th
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'transaction_id'
                                )
                              "
                            >
                              {{ $t("transaction_id") }}
                            </th>
                            <th
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'order_id'
                                )
                              "
                              class="sort"
                              @click="wallet_sortTable('order_id')"
                            >
                              {{ $t("order_id") }}
                            </th>
                            <th
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'transaction_type'
                                )
                              "
                              class="sort"
                              @click="wallet_sortTable('transaction_type')"
                            >
                              {{ $t("transaction_type") }}
                            </th>
                            <th
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'transaction_alias'
                                )
                              "
                              class="sort"
                              @click="wallet_sortTable('transaction_alias')"
                            >
                              {{ $t("transaction_alias") }}
                            </th>
                            <th
                              v-if="
                                wallet_table_selected_fields.includes('amount')
                              "
                              class="sort"
                              @click="wallet_sortTable('amount')"
                            >
                              {{ $t("amount") }}
                            </th>
                            <th
                              v-if="
                                wallet_table_selected_fields.includes('balance')
                              "
                              class="sort"
                              @click="wallet_sortTable('close_balance')"
                            >
                              {{ $t("balance") }}
                            </th>
                            <th
                              v-if="
                                wallet_table_selected_fields.includes('is_due')
                              "
                              class="sort"
                              @click="sortTable('is_due')"
                            >
                              {{ $t("is_due") }}
                            </th>
                            <th
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'due_date'
                                )
                              "
                              class="sort"
                              @click="sortTable('due_date')"
                            >
                              {{ $t("due_date") }}
                            </th>
                            <th
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'created_at'
                                )
                              "
                              class="sort"
                              @click="sortTable('created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :colspan="wallet_table_selected_fields.length"
                              v-if="!wallet_load"
                            >
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              :colspan="wallet_table_selected_fields.length"
                              class="no_data"
                              v-if="wallet_load && wallet.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in wallet"
                            :key="i"
                            v-show="wallet_load"
                          >
                            <td
                              v-if="wallet_table_selected_fields.includes('#')"
                            >
                              {{ i + 1 }}
                            </td>
                            <td
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'transaction_id'
                                )
                              "
                            >
                              {{ item.transaction_id }}
                            </td>
                            <td
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'order_id'
                                )
                              "
                            >
                              {{ item.order_id || "-" }}
                            </td>
                            <td
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'transaction_type'
                                )
                              "
                            >
                              {{ item.transaction_type }}
                            </td>
                            <td
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'transaction_alias'
                                )
                              "
                            >
                              {{ item.transaction_alias }}
                            </td>
                            <td
                              v-if="
                                wallet_table_selected_fields.includes('amount')
                              "
                            >
                              {{ item.amount }}
                            </td>
                            <td
                              v-if="
                                wallet_table_selected_fields.includes('balance')
                              "
                            >
                              {{ item.close_balance }}
                            </td>
                            <td
                              v-if="
                                wallet_table_selected_fields.includes('is_due')
                              "
                            >
                              {{ item.is_due ? $t("is_due") : $t("not_due") }}
                            </td>
                            <td
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'due_date'
                                )
                              "
                              v-html="dateTimeFormat(item.due_date)"
                            ></td>
                            <td
                              v-if="
                                wallet_table_selected_fields.includes(
                                  'created_at'
                                )
                              "
                              v-html="dateTimeFormat(item.created_at)"
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="wallet_currentPage"
                        :total-rows="wallet_rows"
                        :per-page="wallet_perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab
                no-body
                v-if="$can('show products')"
                @click="$router.push({ path: '/vendors/' + id + '/products' })"
              >
                <template v-slot:title>
                  <i class="fas fa-arrow-right ml-2 mr-2"></i>
                  {{ $t("go_to_products") }}
                </template>
              </b-tab>
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Vendor",
  data() {
    return {
      id: "",
      vendor: {},
      load: false,
      stores: [],
      search_key: "",
      old_search_key: "",
      wallet_search_key: "",
      wallet_old_search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      wallet_currentPage: 1,
      wallet_perPage: 1,
      wallet_rows: 1,
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      add_data_icon: null,
      add_data_image: null,
      add_data: {
        lat: 24.774265,
        lng: 46.738586
      },
      image_url: "",
      image_url2: "",

      edit_cities: [],
      search_disabled: false,
      disabled: false,
      sort_key: "",
      sort_type: "asc",
      wallet_search_disabled: false,
      wallet_sort_key: "",
      wallet_sort_type: "asc",

      table_fields: ["#", "image", "name", "created_at", "status", "actions"],
      table_selected_fields: [],
      wallet_table_fields: [
        "#",
        "transaction_id",
        "order_id",
        "transaction_alias",
        "transaction_type",
        "amount",
        "balance",
        "is_due",
        "due_date",
        "created_at"
      ],
      wallet_table_selected_fields: [],
      edit_store_data: {},
      edit_store_disabled: false,
      edit_store_success: "",
      edit_store_error: "",
      edit_store_errors: {},
      edit_store_data_image: null,
      edit_store_data_icon: null,
      wallet: [],
      balance: 0,
      add_wallet_data: {},
      add_wallet_disabled: false,
      add_wallet_success: "",
      add_wallet_error: "",
      add_wallet_errors: {},
      wallet_load: false,
      bank_data: {},
      bank_disabled: false,
      bank_success: "",
      bank_error: "",
      bank_errors: {},
      national_id_image: null,
      national_id_icon: null,
      album_items: [],
      album_index: null,
      commission: 0
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.table_selected_fields = this.$store.state.stores_selected_fields;
    this.wallet_table_selected_fields = this.$store.state.wallet_selected_fields;
    this.getStores();
    this.getWallet();
    this.getVendor();
  },
  watch: {
    currentPage() {
      this.getStores();
    },
    wallet_currentPage() {
      this.getWallet();
    },
    table_selected_fields() {
      this.$store.commit("setStoresSelectedFields", this.table_selected_fields);
    },
    wallet_table_selected_fields() {
      this.$store.commit(
        "setWalletSelectedFields",
        this.wallet_table_selected_fields
      );
    }
  },
  methods: {
    getVendor() {
      return this.axios
        .get("vendors/" + this.id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.vendor = res.result.vendor;
            this.image_url2 = res.result.image_url;
            this.commission = res.result.commission;
            this.bank_data = this.vendor.bank || {};
            this.$set(this.bank_data, "id", this.id);
          } else {
            this.$router.push("/vendors");
          }
        })
        .catch(error => {
          this.handleError("getVendor", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    getStores() {
      return this.axios
        .get("stores/vendor_stores/" + this.id, {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.stores = res.result.stores.data;
            this.rows = res.result.stores.total;
            this.perPage = res.result.stores.per_page;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getStores", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    getWallet() {
      return this.axios
        .get("vendors/wallet/" + this.id, {
          params: {
            page: this.wallet_currentPage,
            search_key: this.wallet_search_key,
            sort_key: this.wallet_sort_key,
            sort_type: this.wallet_sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.wallet = res.result.wallet.data;
            this.wallet_rows = res.result.wallet.total;
            this.wallet_perPage = res.result.wallet.per_page;
            this.balance = res.result.balance;
          }
        })
        .catch(error => {
          this.handleError("getWallet", error);
        })
        .finally(() => {
          this.wallet_load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getStores();
    },
    wallet_sortTable(key) {
      if (this.wallet_sort_key != key) {
        this.wallet_sort_type = "asc";
      } else {
        this.wallet_sort_type = this.wallet_sort_type == "asc" ? "desc" : "asc";
      }
      this.wallet_sort_key = key;
      this.getWallet();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getStores().then(() => {
          this.search_disabled = false;
        });
      }
    },
    wallet_searchAll() {
      if (this.wallet_search_key != this.wallet_old_search_key) {
        this.wallet_old_search_key = this.wallet_search_key;
        this.wallet_search_disabled = true;
        this.getWallet().then(() => {
          this.wallet_search_disabled = false;
        });
      }
    },

    updateVendor() {
      this.add_disabled = true;
      let fd = new FormData();

      if (this.national_id_icon) {
        fd.append(
          "national_id_image_input",
          this.national_id_icon,
          this.national_id_icon.name
        );
      }

      Object.keys(this.vendor).forEach(key => {
        if (this.vendor[key]) {
          fd.append(key, this.vendor[key]);
        }
      });
      this.add_error = this.add_success = "";
      this.axios
        .post("vendors/update", fd)
        .then(response => {
          let res = response.data;
          this.add_errors = {};
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.national_id_image = this.national_id_icon = null;
            this.getVendor();
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("updateVendor", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateVendor", error);
        })
        .finally(() => {
          this.add_disabled = false;
          this.add_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateBank() {
      this.bank_disabled = true;
      this.bank_error = this.bank_success = "";
      this.axios
        .post("vendors/update_bank", this.bank_data)
        .then(response => {
          let res = response.data;
          this.bank_errors = {};
          if (res.error_flag == 0) {
            this.bank_success = res.message;
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.bank_errors = res.message;
            } else {
              this.bank_error = res.message;
            }
          } else {
            this.handleError("updateBank", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateBank", error);
        })
        .finally(() => {
          this.bank_disabled = false;
          this.bank_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    addStore() {
      this.add_disabled = true;
      let fd = new FormData();
      fd.append("vendor_id", this.id);
      if (this.add_data_icon) {
        fd.append(
          "store_logo_input",
          this.add_data_icon,
          this.add_data_icon.name
        );
      }
      Object.keys(this.add_data).forEach(key => {
        if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });
      this.axios
        .post("stores/add", fd)
        .then(response => {
          let res = response.data;
          this.add_error = this.add_success = "";
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.add_errors = {};
            this.getStores();
            this.add_data = {
              lat: 24.774265,
              lng: 46.738586
            };
            this.add_data_image = null;
            this.add_data_icon = null;
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addStore", res.message);
          }
        })
        .catch(error => {
          this.handleError("addStore", error);
        })
        .finally(() => {
          this.add_disabled = false;
          this.add_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateStore() {
      this.edit_store_disabled = true;
      let fd = new FormData();
      fd.append("vendor_id", this.id);
      if (this.edit_store_data_icon) {
        fd.append(
          "store_logo_input",
          this.edit_store_data_icon,
          this.edit_store_data_icon.name
        );
      }
      Object.keys(this.edit_store_data).forEach(key => {
        if (this.edit_store_data[key]) {
          fd.append(key, this.edit_store_data[key]);
        }
      });
      this.edit_store_error = this.edit_store_success = "";
      this.axios
        .post("stores/update", fd)
        .then(response => {
          let res = response.data;
          this.edit_store_errors = {};

          if (res.error_flag == 0) {
            this.edit_store_success = res.message;
            this.edit_store_data = res.result.store;
            this.$set(
              this.stores,
              this.stores.findIndex(e => e.id == this.edit_store_data.id),
              res.result.store
            );
            this.edit_store_data_image = null;
            this.edit_store_data_icon = null;

            document.getElementById("edit_store_modal").scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth"
            });
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_store_errors = res.message;
            } else {
              this.edit_store_error = res.message;
              document.getElementById("edit_store_modal").scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }
          } else {
            this.handleError("updateStore", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateStore", error);
        })
        .finally(() => {
          this.edit_store_disabled = false;
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.stores, "id", id);
      let i = this.findIndex(this.stores, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("stores/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.stores[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    acceptStore(id) {
      let index = this.findIndex(this.stores, "id", id);
      let i = this.findIndex(this.stores, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("stores/acceptStore/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.stores[index].status = "on";
            this.$noty.success(res.message);
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("acceptStore", res.message);
          }
        })
        .catch(error => {
          this.handleError("acceptStore", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteStore(id) {
      let index = this.findIndex(this.stores, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("stores/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.stores.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteStore", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteStore", error);
              });
          }
        });
    },
    updatePosition(event, flag = null) {
      let pos = {};
      if (flag) {
        pos = event;
      } else {
        pos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      }
      this.$set(this.add_data, "lat", pos.lat);
      this.$set(this.add_data, "lng", pos.lng);

      this.$geocoder.send(pos, response => {
        if (response.status == "OK") {
          let address = response.results[0].formatted_address;
          this.$set(this.add_data, "address", address);
          this.$refs.autocomplete.$refs.input.value = address;
        }
      });
    },
    updatePosition2(event, flag = null) {
      let pos = {};
      if (flag) {
        pos = event;
      } else {
        pos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      }

      this.$set(this.edit_store_data, "lat", pos.lat);
      this.$set(this.edit_store_data, "lng", pos.lng);

      this.$geocoder.send(pos, response => {
        if (response.status == "OK") {
          let address = response.results[0].formatted_address;
          this.$set(this.edit_store_data, "address", address);
          this.$refs.autocomplete2.$refs.input.value = address;
        }
      });
    },
    setPlace(place) {
      if (place.geometry) {
        this.$set(this.add_data, "lat", place.geometry.location.lat());
        this.$set(this.add_data, "lng", place.geometry.location.lng());
        this.$set(
          this.add_data,
          "address",
          this.$refs.autocomplete.$refs.input.value
        );
      }
    },
    setPlace2(place) {
      if (place.geometry) {
        this.$set(this.edit_store_data, "lat", place.geometry.location.lat());
        this.$set(this.edit_store_data, "lng", place.geometry.location.lng());
        this.$set(
          this.edit_store_data,
          "address",
          this.$refs.autocomplete2.$refs.input.value
        );
      }
    },
    customizeTable() {
      this.$refs.customize_table_modal.show();
    },
    showEditStore(id) {
      this.edit_store_success = this.edit_store_error = "";
      this.edit_store_errors = {};
      this.edit_store_data_image = this.edit_store_data_icon = null;
      this.edit_store_data = { ...this.stores.find(e => e.id == id) };
      this.$refs.edit_store_modal.show();
    },
    getLocation() {
      if (navigator.geolocation)
        navigator.geolocation.getCurrentPosition(
          this.setLocation,
          this.locationError
        );
      else alert("Geolocation is not supported by this browser.");
    },
    setLocation(pos) {
      let map_pos = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      };
      this.updatePosition(map_pos, 1);
    },
    locationError() {
      /*if (error.PERMISSION_DENIED)
        alert("User denied the request for Geolocation.");
      else
       if (error.POSITION_UNAVAILABLE)
        alert("Location information is unavailable.");
      else if (error.TIMEOUT)
        alert("The request to get user location timed out.");
      else alert("An unknown error occurred.");*/
    },
    wallet_customizeTable() {
      this.$refs.wallet_customize_table_modal.show();
    },
    showAddToWallet() {
      this.add_wallet_success = this.add_wallet_error = "";
      this.add_wallet_errors = {};
      this.$refs.add_wallet_modal.show();
    },
    addToWallet() {
      this.add_wallet_disabled = true;
      this.add_wallet_error = this.add_wallet_success = "";
      this.axios
        .post("vendors/addToWallet", {
          ...this.add_wallet_data,
          vendor_id: this.id
        })
        .then(response => {
          let res = response.data;
          this.add_wallet_errors = {};
          if (res.error_flag == 0) {
            this.add_wallet_success = res.message;
            this.add_wallet_data = {};
            this.getWallet();
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_wallet_errors = res.message;
            } else {
              this.add_wallet_error = res.message;
            }
          } else {
            this.handleError("addToWallet", res.message);
          }
        })
        .catch(error => {
          this.handleError("addToWallet", error);
        })
        .finally(() => {
          this.add_wallet_disabled = false;
        });
    },
    showFullImage(image) {
      this.album_items = [image];
      this.album_index = 0;
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
