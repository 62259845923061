<template>
  <div>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <br />

            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab :title="$t('vendor_payrolls')" no-body>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      {{ $t("select") }} {{ $t("batch") }}:
                      <div
                        class="form-group mb-0"
                        style="display: inline-block;"
                      >
                        <select
                          class="form-control custom-select"
                          style="width: max-content;"
                          v-model="batch_filter"
                        >
                          <option value="" disabled>{{ $t("select") }}</option>
                          <option v-for="(batch, i) in batches" :key="i">{{
                            batch
                          }}</option>
                        </select>
                      </div>
                      <div
                        class="card-tools"
                        style="margin-top:5px"
                        v-if="batch_summary.amount"
                      >
                        <span class="mr-2 ml-2">
                          {{ $t("amounts_due") }}:
                          <b>{{ batch_summary.amount }} {{ $t("sar") }}</b>
                        </span>
                        <span class="mr-2 ml-2">
                          {{ $t("vendors_count") }}:
                          <b>{{ batch_summary.payrolls_count }} </b>
                        </span>
                        <span class="mr-2 ml-2">
                          {{ $t("status") }}:
                          <span
                            :class="
                              'badge badge-' +
                                (batch_summary.is_paid ? 'info' : 'danger')
                            "
                          >
                            {{
                              batch_summary.is_paid
                                ? $t("is_paid")
                                : $t("not_paid")
                            }}
                          </span>
                        </span>
                      </div>
                    </b-card-header>
                    <b-card-header>
                      <button
                        v-if="invoices.length > 0"
                        class="btn btn-sm btn-info"
                        @click="downloadExcel"
                        :disabled="excel_disabled"
                      >
                        {{ $t("export_excel") }}
                        <i
                          class="fa fa-download mr-1 ml-1"
                          v-if="!excel_disabled"
                        ></i>
                        <b-spinner small v-else class="mr-1 ml-1"></b-spinner>
                      </button>

                      <button
                        v-if="invoices.length > 0 && batch_summary.is_paid"
                        class="btn btn-sm btn-success mr-3 ml-3"
                        @click="paidDone"
                        :disabled="paid_done_disabled"
                      >
                        {{ batch_summary.paid_at }}
                        <i
                          class="fa fa-check mr-1 ml-1"
                          v-if="!paid_done_disabled"
                        ></i>
                        <b-spinner small v-else class="mr-1 ml-1"></b-spinner>
                      </button>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>
                              #
                            </th>
                            <th>
                              {{ $t("vendor") }}
                            </th>
                            <th>
                              {{ $t("amount") }}
                            </th>

                            <th v-if="false">
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="4"
                              class="no_data"
                              v-if="load && invoices.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in invoices"
                            :key="i"
                            v-show="load"
                          >
                            <td>
                              {{ i + 1 }}
                            </td>

                            <td>
                              <router-link
                                :to="
                                  '/vendors/' +
                                    (item.vendor ? item.vendor.id : '')
                                "
                              >
                                {{ (item.vendor || {}).name || "-" }}
                              </router-link>
                              <br />
                              {{ (item.vendor || {}).mobile || "-" }}
                            </td>
                            <td>{{ item.amount }} {{ $t("sar") }}</td>

                            <td class="actions-group" v-if="false">
                              <button
                                v-if="$can('delete invoices')"
                                @click="deleteInvoice(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card>
                </b-card-text>
              </b-tab>
              <b-tab :title="$t('delivery_company_payrolls')" no-body>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      {{ $t("select") }} {{ $t("batch") }}:
                      <div
                        class="form-group mb-0"
                        style="display: inline-block;"
                      >
                        <select
                          class="form-control custom-select"
                          style="width: max-content;"
                          v-model="c_batch_filter"
                        >
                          <option value="" disabled>{{ $t("select") }}</option>
                          <option v-for="(batch, i) in c_batches" :key="i">{{
                            batch
                          }}</option>
                        </select>
                      </div>
                      <div
                        class="card-tools"
                        style="margin-top:5px"
                        v-if="c_batch_summary.amount"
                      >
                        <span class="mr-2 ml-2">
                          {{ $t("amounts_due") }}:
                          <b>{{ c_batch_summary.amount }} {{ $t("sar") }}</b>
                        </span>
                        <span class="mr-2 ml-2">
                          {{ $t("companies_count") }}:
                          <b>{{ c_batch_summary.payrolls_count }} </b>
                        </span>
                        <span class="mr-2 ml-2">
                          {{ $t("status") }}:
                          <span
                            :class="
                              'badge badge-' +
                                (c_batch_summary.is_paid ? 'info' : 'danger')
                            "
                          >
                            {{
                              c_batch_summary.is_paid
                                ? $t("is_paid")
                                : $t("not_paid")
                            }}
                          </span>
                        </span>
                      </div>
                    </b-card-header>
                    <b-card-header>
                      <button
                        v-if="c_invoices.length > 0"
                        class="btn btn-sm btn-info"
                        @click="c_downloadExcel"
                        :disabled="c_excel_disabled"
                      >
                        {{ $t("export_excel") }}
                        <i
                          class="fa fa-download mr-1 ml-1"
                          v-if="!c_excel_disabled"
                        ></i>
                        <b-spinner small v-else class="mr-1 ml-1"></b-spinner>
                      </button>

                      <button
                        v-if="c_invoices.length > 0 && c_batch_summary.is_paid"
                        class="btn btn-sm btn-success mr-3 ml-3"
                        @click="c_paidDone"
                        :disabled="c_paid_done_disabled"
                      >
                        {{ c_batch_summary.paid_at }}

                        <i
                          class="fa fa-check mr-1 ml-1"
                          v-if="!c_paid_done_disabled"
                        ></i>
                        <b-spinner small v-else class="mr-1 ml-1"></b-spinner>
                      </button>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>
                              #
                            </th>
                            <th>
                              {{ $t("delivery_company") }}
                            </th>
                            <th>
                              {{ $t("amount") }}
                            </th>

                            <th v-if="false">
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="4"
                              class="no_data"
                              v-if="load && c_invoices.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in c_invoices"
                            :key="i"
                            v-show="load"
                          >
                            <td>
                              {{ i + 1 }}
                            </td>

                            <td>
                              <router-link to="/delivery_company">
                                {{ (item.delivery_company || {}).name || "-" }}
                              </router-link>
                            </td>
                            <td>{{ item.amount }} {{ $t("sar") }}</td>

                            <td class="actions-group" v-if="false">
                              <button
                                v-if="$can('delete invoices')"
                                @click="deleteInvoice(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="c_currentPage"
                        :total-rows="c_rows"
                        :per-page="c_perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card>
                </b-card-text>
              </b-tab>
            </b-tabs>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Invoices",
  data() {
    return {
      load: false,
      invoices: [],
      c_invoices: [],
      search_key: "",
      c_search_key: "",
      old_search_key: "",
      c_old_search_key: "",
      search_disabled: false,
      c_search_disabled: false,
      disabled_s: [],
      c_disabled_s: [],
      currentPage: 1,
      c_currentPage: 1,
      perPage: 1,
      c_perPage: 1,
      rows: 0,
      c_rows: 0,
      w_request: {},
      sort_key: "",
      c_sort_key: "",
      sort_type: "asc",
      c_sort_type: "asc",
      batch_filter: "",
      c_batch_filter: "",
      batches: [],
      batch_summary: {},
      excel_disabled: false,
      paid_done_disabled: false,
      c_batches: [],
      c_batch_summary: {},
      c_excel_disabled: false,
      c_paid_done_disabled: false
    };
  },
  created() {
    this.getInvoices();
    this.c_getInvoices();
  },
  watch: {
    currentPage() {
      this.getInvoices();
    },
    c_currentPage() {
      this.c_getInvoices();
    },
    table_selected_fields() {
      this.$store.commit(
        "setInvoicesSelectedFields",
        this.table_selected_fields
      );
    },
    batch_filter() {
      this.load = false;
      this.batch_summary = {};
      this.getInvoices();
    },
    c_batch_filter() {
      this.load = false;
      this.c_batch_summary = {};
      this.c_getInvoices();
    }
  },
  methods: {
    getInvoices() {
      return this.axios
        .get("vendor_payrolls", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type,
            batch_filter: this.batch_filter
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.invoices = res.result.payrolls.data;
            this.rows = res.result.payrolls.total;
            this.perPage = res.result.payrolls.per_page;
            this.batches = res.result.batches;
            this.batch_summary = res.result.batch_summary;
            this.batch_filter = this.batch_summary.batch_filter;
          }
        })
        .catch(error => {
          this.handleError("getInvoices", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    c_getInvoices() {
      return this.axios
        .get("delivery_company_payrolls", {
          params: {
            page: this.c_currentPage,
            search_key: this.c_search_key,
            sort_key: this.c_sort_key,
            sort_type: this.c_sort_type,
            batch_filter: this.c_batch_filter
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.c_invoices = res.result.payrolls.data;
            this.c_rows = res.result.payrolls.total;
            this.c_perPage = res.result.payrolls.per_page;
            this.c_batches = res.result.batches;
            this.c_batch_summary = res.result.batch_summary;
            this.c_batch_filter = this.batch_summary.batch_filter;
          }
        })
        .catch(error => {
          this.handleError("getInvoices", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getInvoices();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getInvoices().then(() => {
          this.search_disabled = false;
        });
      }
    },
    deleteInvoice(id) {
      let index = this.findIndex(this.invoices, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("vendor_payrolls/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.invoices.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteInvoice", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteInvoice", error);
              });
          }
        });
    },
    paidDone() {
      //let index = this.findIndex(this.invoices, "id", id);
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.paid_done_disabled = true;
            this.axios
              .post("vendor_payrolls/paid_done/" + this.batch_filter)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.$set(this.batch_summary, "is_paid", 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("paidDone", res.message);
                }
              })
              .catch(error => {
                this.handleError("paidDone", error);
              })
              .finally(() => {
                this.paid_done_disabled = false;
              });
          }
        });
    },
    downloadExcel() {
      this.excel_disabled = true;
      let time = new Date().getTime();
      this.axios
        .get("vendor_payrolls/download_excel", {
          params: {
            batch_filter: this.batch_filter
          },
          responseType: "arraybuffer"
        })
        .then(response => {
          let blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Invoices_" + time + ".xlsx";
          link.click();
        })
        .catch(error => {
          this.handleError("downloadExcel", error);
        })
        .finally(() => {
          this.excel_disabled = false;
        });
    },
    c_sortTable(key) {
      if (this.c_sort_key != key) {
        this.c_sort_type = "asc";
      } else {
        this.c_sort_type = this.c_sort_type == "asc" ? "desc" : "asc";
      }
      this.c_sort_key = key;
      this.c_getInvoices();
    },
    c_searchAll() {
      if (this.c_search_key != this.c_old_search_key) {
        this.c_old_search_key = this.c_search_key;
        this.c_search_disabled = true;
        this.c_getInvoices().then(() => {
          this.c_search_disabled = false;
        });
      }
    },
    c_deleteInvoice(id) {
      let index = this.findIndex(this.c_invoices, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("delivery_company_payrolls/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.c_invoices.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteInvoice", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteInvoice", error);
              });
          }
        });
    },
    c_paidDone() {
      //let index = this.findIndex(this.invoices, "id", id);
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.c_paid_done_disabled = true;
            this.axios
              .post("delivey_company_payrolls/paid_done/" + this.c_batch_filter)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.$set(this.c_batch_summary, "is_paid", 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("paidDone", res.message);
                }
              })
              .catch(error => {
                this.handleError("paidDone", error);
              })
              .finally(() => {
                this.c_paid_done_disabled = false;
              });
          }
        });
    },
    c_downloadExcel() {
      this.c_excel_disabled = true;
      let time = new Date().getTime();
      this.axios
        .get("delivery_company_payrolls/download_excel", {
          params: {
            batch_filter: this.c_batch_filter
          },
          responseType: "arraybuffer"
        })
        .then(response => {
          let blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Invoices_" + time + ".xlsx";
          link.click();
        })
        .catch(error => {
          this.handleError("downloadExcel", error);
        })
        .finally(() => {
          this.c_excel_disabled = false;
        });
    }
  }
};
</script>
