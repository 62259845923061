<template>
  <div>
    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="order_modal"
      :title="$t(order.order_type)"
      header-class="new_modal_header"
      size="lg"
      hide-footer
    >
      <div class="row" style="margin: -15px">
        <div class="col-md-8 order_det" v-if="order.user">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>{{ $t("order_id") }}</label>
              <a href="#" class="text-muted text-sm">{{ order.id }}</a>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("user") }}</label>
              {{ (order.user || {})["name"] }}
              <br v-if="(order.user || {})['name']" />
              <a :href="'tel:' + (order.user || {})['mobile']">{{
                (order.user || {})["mobile"]
              }}</a>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("vendor") }}</label>
              {{ (order.vendor || {})["name"] }}
              <br />
              <a :href="'tel:' + (order.vendor || {})['contact_mobile']">{{
                (order.vendor || {})["contact_mobile"]
              }}</a>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("store") }}</label>
              {{ (order.store || {})["store_name"] }}
              <br />
              <a :href="'tel:' + (order.store || {})['store_contact_mobile']">{{
                (order.store || {})["store_contact_mobile"]
              }}</a>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("address") }}</label>
              {{
                order.shipping_address
                  ? order.shipping_city["name_" + $i18n.locale] +
                    ", " +
                    order.shipping_address
                  : "0"
              }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("products") }}:</label>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" v-if="order.products">
              <thead>
                <tr>
                  <th style="vertical-align: middle; width: 10px">#</th>
                  <th style="vertical-align: middle">{{ $t("name") }}</th>
                  <th style="vertical-align: middle">{{ $t("qty") }}</th>
                  <th>
                    {{ $t("price") }} <br />
                    ( {{ $t("sar") }} )
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(prod, i) in order.products" :key="i">
                  <td>{{ i + 1 }}.</td>
                  <td>
                    <div>
                      <img
                        :src="image_url + '/' + prod.image"
                        style="border-radius: 50%; margin: 5px"
                        @error="$event.target.src = '/images/placeholder.jpg'"
                      />
                    </div>
                    <div>
                      <b>{{ prod["name_" + $i18n.locale] }}</b>
                      <br />

                      <span
                        v-for="(option, i) in prod.options"
                        :key="i"
                        style="color: red; fontsize: 14px"
                      >
                        {{ option[`name_${$i18n.locale}`] }}:
                        {{ option[`option_value_name_${$i18n.locale}`] }}
                        <i v-if="i < prod.options.length - 1"><br /></i>
                      </span>
                    </div>
                  </td>
                  <td>X {{ prod.qty }}</td>
                  <td>
                    <b v-if="(prod.pivot || {}).new_price">{{
                      (prod.pivot || {}).new_price * (prod.pivot || {}).qty
                    }}</b>
                    <b v-else>{{ prod.price * prod.qty }}</b>
                    &nbsp; &nbsp;
                    <del v-if="(prod.pivot || {}).new_price">{{
                      prod.price * prod.qty
                    }}</del>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label>{{ $t("products_price") }}</label>
              {{
                order.products_price === 0
                  ? $t("pending_price")
                  : order.products_price
              }}
              {{ order.products_price !== 0 ? $t("sar") : "" }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("delivery_fees") }}</label>
              {{ order.delivery_fees }} {{ $t("sar") }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("extra_fees") }}</label>
              {{ order.extra_fees }} {{ $t("sar") }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("vat") }}</label>
              {{ order.vat }} {{ $t("sar") }}
            </div>
            <div class="form-group col-md-6" v-if="order.coupon">
              <label>{{ $t("discount") }}</label>
              {{ (order.coupon || {}).discount }} {{ $t("sar") }}
              <span class="btn btn-sm btn-light text-sm text-muted">{{
                (order.coupon || {}).code
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("total") }}</label>
              {{
                order.total_price === 0
                  ? $t("pending_price")
                  : order.total_price
              }}
              {{ order.total_price !== 0 ? $t("sar") : "" }}
            </div>
            <div class="form-group col-md-6" v-if="order.payment_method">
              <label>{{ $t("payment_method") }}</label>
              <span v-tooltip="order.payment_method"
                >{{ $t(order.payment_method) }} -
                {{ order.payment_option }}</span
              >
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("order_type") }}</label>
              {{ $t(order.order_type) }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("dispatch_number") }}</label>
              {{ order.shipping_dispatch_confirmation_number || "-" }}
            </div>

            <div
              class="form-group col-md-12"
              v-if="order.status == 'CANCELLED'"
            >
              <label>{{ $t("c_reason") }}</label>
              {{ (order.c_reason || {})["name_" + $i18n.locale] || "-" }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("notes") }}</label>
              {{ order.notes || "-" }}
            </div>

            <button
              v-if="!['HOLD'].includes(order.status)"
              class="btn btn-success btn-md d-block mx-auto"
              @click="showDeliveryOptions(order.id)"
            >
              {{ $t("delivery_options") }}
              <i class="fas fa-truck"></i>
            </button>

            <button
              v-if="
                [
                  'DELIVERING',
                  'DELIVERED',
                  'RATING',
                  'COMPLETED',
                  'IS_READY',
                ].includes(order.status) &&
                (order.delivery_company || {}).main_name === 'dhl'
              "
              class="btn btn-primary btn-md d-block mx-auto"
              @click="downloadBill(order.id)"
            >
              {{ $t("bill_of_lading") }}
              <i class="fas fa-file-invoice"></i>
            </button>
          </div>
        </div>
        <div class="col-md-4 order-steps">
          <ul class="timeline">
            <li v-for="(order_status, i) in order.tracking" :key="i">
              <p
                class="timeline-date"
                v-tooltip="
                  $moment(order_status.created_at)
                    .tz($store.state.timezone)
                    .format('Y-MM-DD hh:mm A')
                "
              >
                {{
                  $moment(order_status.created_at)
                    .tz($store.state.timezone)
                    .format("hh:mm")
                }}
                {{
                  $moment(order_status.created_at)
                    .tz($store.state.timezone)
                    .locale($i18n.locale)
                    .format("A")
                }}
              </p>
              <div
                :class="
                  'timeline-content active ' + (i == 0 ? 'process_done' : '')
                "
              >
                {{ $t(order_status.status) }}
              </div>
            </li>

            <button
              v-if="!['DELIVERED'].includes(order.status)"
              class="btn btn-info btn-sm d-block mx-auto"
              @click="updateStatus(order.id)"
            >
              <i
                :class="
                  'm-1 fas fa-arrow-' +
                  ($i18n.locale == 'ar' ? 'left' : 'right')
                "
              ></i>
              {{ $t("go_to_next_status") }}
            </button>

            <button
              v-if="!['CANCELLED'].includes(order.status)"
              class="btn btn-danger btn-sm d-block mt-3 mx-auto"
              @click="showCancel(order.id)"
            >
              <i class="fa fa-times"></i>
              {{ $t("cancel_order") }}
            </button>
            <button
              v-if="
                ['IS_READY'].includes(order.status) &&
                !order.shipping_dispatch_confirmation_number
              "
              class="btn btn-warning btn-sm d-block mt-3 mx-auto"
              @click="pickupOrder(order.id)"
            >
              <i class="fa fa-forward"></i>
              Pickup - طلب التوصيل
            </button>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="delivery_options_modal"
      centered
      :title="$t('delivery_options')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="
              delivery_options_error || delivery_options_success ? true : false
            "
            :variant="delivery_options_error ? 'danger' : 'success'"
            dismissible
            @dismissed="delivery_options_error = delivery_options_success = ''"
          >
            {{ delivery_options_error || delivery_options_success }}
          </b-alert>

          <div class="form-row">
            <div class="form-group col-md-12">
              <label>{{ $t("delivery_company") }}</label>
              <select
                :disabled="
                  !['NEW', 'ACCEPTED', 'PREPAIRING', 'IS_READY'].includes(
                    order.status
                  )
                "
                class="form-control custom-select"
                :class="{ 'is-invalid': delivery_options_errors.dimension_id }"
                v-model="delivery_options_data.delivery_company_id"
              >
                <option value="" disabled>{{ $t("select") }}</option>
                <option
                  v-for="(delivery_company, i) in delivery_companies"
                  :key="i"
                  :value="delivery_company.id"
                >
                  {{ delivery_company.name }}
                </option>
              </select>
              <span class="error invalid-feedback">{{
                (delivery_options_errors.delivery_company_id || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-12">
              <label>{{ $t("pack") }}</label>
              <select
                :disabled="
                  !delivery_options_data.delivery_company_id ||
                  !['NEW', 'ACCEPTED', 'PREPAIRING', 'IS_READY'].includes(
                    order.status
                  )
                "
                class="form-control custom-select"
                :class="{
                  'is-invalid': delivery_options_errors.pack_id,
                }"
                v-model="delivery_options_data.pack_id"
              >
                <option value="" disabled>{{ $t("select") }}</option>
                <option
                  v-for="(pack, i) in company_packs"
                  :key="i"
                  :value="pack.id"
                >
                  {{ pack.p_length }} × {{ pack.p_width }} ×
                  {{ pack.p_height }} [{{ pack.p_weight }}]KG
                </option>
              </select>
              <span class="error invalid-feedback">{{
                (delivery_options_errors.pack_id || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            v-if="
              ['NEW', 'ACCEPTED', 'PREPAIRING', 'IS_READY'].includes(
                order.status
              )
            "
            type="submit"
            class="btn btn-info mt-2"
            :disabled="delivery_options_disabled"
            @click.prevent="updateDeliveryOptions"
          >
            {{ $t("save") }}
            <b-spinner small v-if="delivery_options_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <b-modal
      ref="cancel_modal"
      centered
      :title="$t('cancel_order')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="cancel_error || cancel_success ? true : false"
            :variant="cancel_error ? 'danger' : 'success'"
            dismissible
            @dismissed="cancel_error = cancel_success = ''"
          >
            {{ cancel_error || cancel_success }}
          </b-alert>

          <div class="form-row">
            <div class="form-group col-md-12">
              <label>{{ $t("c_reason") }}</label>
              <select
                class="form-control custom-select"
                :class="{ 'is-invalid': cancel_errors.reason_id }"
                v-model="cancel_data.reason_id"
              >
                <option value="" disabled>{{ $t("select") }}</option>
                <option
                  v-for="(reason, i) in c_reasons"
                  :key="i"
                  :value="reason.id"
                >
                  {{ reason["name_" + $i18n.locale] }}
                </option>
              </select>
              <span class="error invalid-feedback">{{
                (cancel_errors.reason_id || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="cancel_disabled"
            @click.prevent="cancelOrder"
          >
            {{ $t("save") }}
            <b-spinner small v-if="cancel_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <br />
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-card-text>
              <b-card no-body class="card">
                <b-card-header>
                  {{ $t("status") }}:
                  <div class="fom-group" style="display: inline-block">
                    <select
                      class="form-control custom-select"
                      style="width: max-content"
                      v-model="order_status"
                    >
                      <option value="">{{ $t("all") }}</option>
                      <option
                        v-for="(status, i) in statuses"
                        :key="i"
                        :value="status"
                      >
                        {{ $t(status) }}
                      </option>
                    </select>
                  </div>
                  <div class="card-tools">
                    <div class="input-group input-group-sm">
                      <i
                        class="fas fa-cog c_pointer customize_table"
                        :title="$t('customize_table')"
                        @click="customizeTable"
                      ></i>

                      <input
                        type="text"
                        class="form-control float-right"
                        :placeholder="$t('search')"
                        v-model="search_key"
                      />
                      <div class="input-group-append">
                        <button
                          type="submit"
                          :disabled="search_disabled"
                          @click.prevent="searchAll"
                          class="btn btn-default"
                        >
                          <i
                            class="fa fa-spinner fa-spin"
                            v-if="search_disabled"
                          ></i>
                          <i class="fas fa-search" v-else></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </b-card-header>
                <!-- /.card-header -->
                <b-card-body class="table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th v-if="table_selected_fields.includes('#')">#</th>
                        <th
                          class="sort"
                          @click="sortTable('id')"
                          v-if="table_selected_fields.includes('order_id')"
                        >
                          {{ $t("order_id") }}
                        </th>
                        <th
                          class="sort"
                          @click="sortTable('user_id')"
                          v-if="table_selected_fields.includes('user')"
                        >
                          {{ $t("user") }}
                        </th>
                        <th
                          class="sort"
                          @click="sortTable('store_id')"
                          v-if="table_selected_fields.includes('store')"
                        >
                          {{ $t("store") }}
                        </th>
                        <th
                          class="sort"
                          @click="sortTable('vendor_id')"
                          v-if="table_selected_fields.includes('vendor')"
                        >
                          {{ $t("vendor") }}
                        </th>
                        <th
                          class="sort"
                          @click="sortTable('products_price')"
                          v-if="
                            table_selected_fields.includes('products_price')
                          "
                        >
                          {{ $t("products_price") }}
                        </th>
                        <th
                          class="sort"
                          @click="sortTable('delivery_fees')"
                          v-if="table_selected_fields.includes('delivery_fees')"
                        >
                          {{ $t("delivery_fees") }}
                        </th>
                        <th
                          class="sort"
                          @click="sortTable('total_price')"
                          v-if="table_selected_fields.includes('total')"
                        >
                          {{ $t("total") }}
                        </th>
                        <th
                          class="sort"
                          @click="sortTable('status')"
                          v-if="table_selected_fields.includes('status')"
                        >
                          {{ $t("status") }}
                        </th>
                        <th
                          class="sort"
                          @click="sortTable('updated_at')"
                          v-if="table_selected_fields.includes('created_at')"
                        >
                          {{ $t("last_updated") }}
                        </th>
                        <th v-if="table_selected_fields.includes('actions')">
                          {{ $t("actions") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          :colspan="table_selected_fields.length"
                          v-if="!load"
                        >
                          <i class="fa fa-sync fa-spin load-con"></i>
                        </td>
                        <td
                          :colspan="table_selected_fields.length"
                          class="no_data"
                          v-if="load && orders.length == 0"
                        >
                          {{ $t("no_data") }}
                        </td>
                      </tr>
                      <tr v-for="(item, i) in orders" :key="i" v-show="load">
                        <td v-if="table_selected_fields.includes('#')">
                          {{ i + 1 }}
                        </td>
                        <td v-if="table_selected_fields.includes('order_id')">
                          {{ item.id }}
                        </td>
                        <td v-if="table_selected_fields.includes('user')">
                          {{ (item.user || {})["name"] }}
                          <span v-if="item.user.name"><br /></span>

                          {{ (item.user || {})["mobile"] }}
                        </td>
                        <td v-if="table_selected_fields.includes('store')">
                          {{ (item.store || {})["store_name"] }}
                          <br />
                          {{ (item.store || {})["store_contact_mobile"] }}
                        </td>
                        <td v-if="table_selected_fields.includes('vendor')">
                          {{ (item.vendor || {})["name"] }}
                          <br />
                          {{ (item.vendor || {})["contact_mobile"] }}
                        </td>
                        <td
                          v-if="
                            table_selected_fields.includes('products_price')
                          "
                          :style="
                            item.products_price === 0
                              ? 'max-width: 125px; white-space: normal !important;'
                              : ''
                          "
                        >
                          {{
                            item.products_price === 0
                              ? $t("pending_price")
                              : item.products_price
                          }}
                          {{ item.products_price !== 0 ? $t("sar") : "" }}
                        </td>
                        <td
                          v-if="table_selected_fields.includes('delivery_fees')"
                          :style="
                            item.delivery_fees === 0
                              ? 'max-width: 125px; white-space: normal !important;'
                              : ''
                          "
                        >
                          {{
                            item.delivery_fees === 0
                              ? $t("pending_price")
                              : item.delivery_fees
                          }}
                          {{ item.delivery_fees !== 0 ? $t("sar") : "" }}
                        </td>
                        <td
                          v-if="table_selected_fields.includes('total')"
                          :style="
                            item.total_price === 0
                              ? 'max-width: 125px; white-space: normal !important;'
                              : ''
                          "
                        >
                          {{
                            item.total_price === 0
                              ? $t("pending_price")
                              : item.total_price
                          }}
                          {{ item.total_price !== 0 ? $t("sar") : "" }}
                          <i
                            class="fas fa-money-bill"
                            v-if="
                              ['CASH'].includes(item.payment_method) &&
                              (item.total_price !== 0 ||
                                !['PENDING'].includes(item.status))
                            "
                            v-tooltip="$t('cash')"
                          ></i>
                          <i
                            class="fas fa-wallet"
                            v-if="
                              ['WALLET'].includes(item.payment_method) &&
                              (item.total_price !== 0 ||
                                !['PENDING'].includes(item.status))
                            "
                            v-tooltip="$t('wallet')"
                          ></i>

                          <i
                            class="fas fa-credit-card"
                            v-if="
                              item.payment_method == 'CARD' &&
                              !['PENDING'].includes(item.status)
                            "
                            v-tooltip="$t('card')"
                          ></i>
                          <img
                            src="/images/stc.png"
                            width="25"
                            height="20"
                            style="
                              width: 22px;
                              height: auto;
                              object-fit: contain;
                              -o-object-fit: contain;
                            "
                            v-if="
                              item.payment_method == 'STC' &&
                              !['PENDING'].includes(item.status)
                            "
                            v-tooltip="$t('stc')"
                          />
                        </td>
                        <td v-if="table_selected_fields.includes('status')">
                          <label
                            :class="
                              'badge badge-' +
                              (order_statuses[item.status.toLowerCase()] || '')
                            "
                          >
                            {{
                              item.status.toLowerCase() !== "pending"
                                ? $t(item.status)
                                : $t("status_pending")
                            }}
                          </label>
                        </td>
                        <td
                          v-if="table_selected_fields.includes('created_at')"
                          v-html="dateTimeFormat(item.updated_at)"
                        ></td>
                        <td
                          class="actions-group"
                          v-if="table_selected_fields.includes('actions')"
                        >
                          <button
                            v-if="
                              $can('edit orders') &&
                              item.status == 'CANCELLED' &&
                              ['CARD', 'WALLET'].includes(item.payment_method)
                            "
                            class="btn btn-secondary btn-sm"
                            v-tooltip="$t('refund')"
                            @click="refundOrder(item.id)"
                          >
                            <i class="fas fa-undo-alt"></i>
                          </button>
                          <button
                            v-if="$can('show orders')"
                            class="btn btn-info btn-sm"
                            v-tooltip="$t('show')"
                            @click="showOrder(item.id)"
                          >
                            <i class="fas fa-eye"> </i>
                          </button>
                          <button
                            v-if="$can('delete orders')"
                            @click="deleteOrder(item.id)"
                            class="btn btn-danger btn-sm"
                            v-tooltip="$t('delete')"
                          >
                            <i class="fas fa-trash"> </i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
                <!-- /.card-body -->
                <b-card-footer class="card-footer clearfix">
                  <b-pagination
                    class="m-2"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    :prev-text="$t('previous')"
                    :next-text="$t('next')"
                    align="right"
                    :first-number="true"
                    :last-number="true"
                  ></b-pagination>
                </b-card-footer>
              </b-card>
            </b-card-text>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Orders",
  data() {
    return {
      load: false,
      orders: [],
      order: {},
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      order_status: "",
      statuses: [
        "NEW",
        "ACCEPTED",
        "PREPAIRING",
        "IS_READY",
        "DELIVERING",
        "DELIVERED",
        "RATING",
        "COMPLETED",
        "REJECTED",
        "CANCELLED",
        "REFUNDED",
        "HOLD",
      ],
      order_statuses: {
        new: "primary",
        accepted: "secondary",
        prepairing: "info",
        is_ready: "success",
        delivering: "secondary",
        delivered: "secondary",
        rating: "danger",
        pending: "warning",
        completed: "secondary",
        rejected: "danger",
        cancelled: "danger",
        refunded: "primary",
        hold: "danger",
      },
      sort_key: "",
      sort_type: "asc",
      table_fields: [
        "#",
        "order_id",
        "user",
        "store",
        "vendor",
        "products_price",
        "delivery_fees",
        "total",
        "status",
        "created_at",
        "actions",
      ],
      table_selected_fields: [],
      page_type: null,
      image_url: "",
      delivery_options_data: {},
      delivery_options_success: "",
      delivery_options_error: "",
      delivery_options_errors: "",
      delivery_options_disabled: false,
      delivery_companies: [],
      companies: [],
      company_packs: [],
      cancel_success: "",
      cancel_error: "",
      cancel_errors: {},
      cancel_data: {
        reason_id: "",
      },
      cancel_disabled: false,
      c_reasons: [],
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.orders_selected_fields;
    let route_name = this.$route.name;

    if (route_name == "LiveOrders") {
      this.statuses = [
        "NEW",
        "ACCEPTED",
        "PREPAIRING",
        "IS_READY",
        "DELIVERING",
        "DELIVERED",
        "RATING",
        "COMPLETED",
        "REJECTED",
        "CANCELLED",
        "REFUNDED",
        "HOLD",
      ];
      this.page_type = "live";
    } else if (route_name == "PastOrders") {
      this.statuses = ["DELIVERED", "RATING", "COMPLETED"];
      this.page_type = "past";
    } else if (route_name == "CancelledOrders") {
      this.statuses = ["REJECTED", "CANCELLED", "REFUNDED"];
      this.page_type = "cancelled";
    }
    this.getOrders();
    this.getDeliveryCompanies();
    this.getCReasons();
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
    order_status() {
      this.getOrders();
    },
    table_selected_fields() {
      this.$store.commit("setOrdersSelectedFields", this.table_selected_fields);
    },
    $route() {
      let c_route_name = this.$route.name;
      this.order_status = "";
      if (c_route_name == "LiveOrders") {
        this.statuses = [
          "NEW",
          "ACCEPTED",
          "PREPAIRING",
          "IS_READY",
          "DELIVERING",
          "DELIVERED",
          "RATING",
          "COMPLETED",
          "REJECTED",
          "CANCELLED",
          "HOLD",
        ];
        this.page_type = "live";
      } else if (c_route_name == "PastOrders") {
        this.statuses = ["DELIVERED", "RATING", "COMPLETED"];
        this.page_type = "past";
      } else if (c_route_name == "CancelledOrders") {
        this.statuses = ["REJECTED", "CANCELLED", "REFUNDED"];
        this.page_type = "cancelled";
      }

      this.load = false;
      this.getOrders();
    },
    "delivery_options_data.delivery_company_id"() {
      if (this.delivery_options_data.delivery_company_id) {
        this.company_packs = this.companies.find(
          (e) => e.id == this.delivery_options_data.delivery_company_id
        ).packs;
      }
    },
  },
  methods: {
    getOrders() {
      this.load = false;
      return this.axios
        .get("orders", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            status: this.order_status,
            sort_key: this.sort_key,
            sort_type: this.sort_type,
            page_type: this.page_type,
          },
        })
        .then((response) => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.orders = res.result.orders.data;
            this.rows = res.result.orders.pagination.total;
            this.perPage = res.result.orders.pagination.per_page;
            this.image_url = res.result.image_url;
          }
        })
        .catch((error) => {
          this.handleError("getOrders", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getOrders();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getOrders().then(() => {
          this.search_disabled = false;
        });
      }
    },
    updateStatus(id) {
      let index = this.findIndex(this.orders, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("change_order_msg"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content",
        })
        .then((value) => {
          if (value) {
            this.axios
              .post("orders/update_status/" + id)
              .then((response) => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.$set(
                    this.orders[index],
                    "status",
                    res.result.new_status_track.status
                  );
                  this.orders[index].tracking.push({
                    ...res.result.new_status_track,
                  });
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("updateStatus", res.message);
                }
              })
              .catch((error) => {
                this.handleError("updateStatus", error);
              });
          }
        });
    },
    pickupOrder(id) {
      let index = this.findIndex(this.orders, "id", id);
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content",
        })
        .then((value) => {
          if (value) {
            this.axios
              .post("orders/pickup", {
                id,
              })
              .then((response) => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.$noty.success(res.message);
                  this.$set(
                    this.orders[index],
                    "shipping_dispatch_confirmation_number",
                    res.result.shipping_dispatch_confirmation_number
                  );
                  this.$set(
                    this.order,
                    "shipping_dispatch_confirmation_number",
                    res.result.shipping_dispatch_confirmation_number
                  );
                  this.order = res.result.order;
                  this.getOrders();
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("pickupOrder", res.message);
                }
              })
              .catch((error) => {
                this.handleError("pickupOrder", error);
              });
          }
        });
    },
    deleteOrder(id) {
      let index = this.findIndex(this.orders, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content",
        })
        .then((value) => {
          if (value) {
            this.axios
              .post("orders/delete/" + id)
              .then((response) => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.orders.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteOrder", res.message);
                }
              })
              .catch((error) => {
                this.handleError("deleteOrder", error);
              });
          }
        });
    },
    refundOrder(id) {
      let index = this.orders.findIndex((e) => e.id == id);
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content",
        })
        .then((value) => {
          if (value) {
            this.axios
              .post("orders/refund", {
                id: id,
              })
              .then((response) => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.$set(this.orders[index], "status", "REFUNDED");
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("refundOrder", res.message);
                }
              })
              .catch((error) => {
                this.handleError("refundOrder", error);
              });
          }
        });
    },
    getCReasons() {
      return this.axios
        .get("c_reasons", {
          params: {
            status: "on",
          },
        })
        .then((response) => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.c_reasons = res.result.c_reasons;
          }
        });
    },
    showCancel(id) {
      this.cancel_success = this.cancel_error = "";
      this.cancel_errors = {};
      this.$set(this.cancel_data, "id", id);
      this.$refs.cancel_modal.show();
    },
    cancelOrder() {
      this.cancel_disabled = true;
      this.cancel_error = "";
      let index = this.orders.findIndex((e) => e.id == this.cancel_data.id);
      this.axios
        .post("orders/cancel_order", this.cancel_data)
        .then((response) => {
          this.cancel_errors = {};
          let res = response.data;
          if (res.error_flag == 0) {
            this.$set(
              this.orders[index],
              "status",
              res.result.new_status_track.status
            );
            this.orders[index].tracking.push({
              ...res.result.new_status_track,
            });
            this.$noty.success(res.message);
            this.$refs.order_modal.hide();
            this.$refs.cancel_modal.hide();
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.cancel_errors = res.message;
            } else {
              this.cancel_error = res.message;
            }
          } else {
            this.handleError("cancelOrder", res.message);
          }
        })
        .catch((error) => {
          this.handleError("cancelOrder", error);
        })
        .finally(() => {
          this.cancel_disabled = false;
        });
    },
    showOrder(id) {
      this.order = {};
      this.delivery_options_data = {};
      this.order = { ...this.orders.find((e) => e.id == id) };
      this.$refs.order_modal.show();
    },
    getDeliveryCompanies() {
      return this.axios
        .get("delivery_companies", {
          params: {
            status: "on",
          },
        })
        .then((response) => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.companies = res.result.delivery_companies;
          }
        })
        .catch((error) => {
          this.handleError("getDeliveryCompanies", error);
        });
    },
    showDeliveryOptions() {
      this.delivery_options_error = this.delivery_options_success = "";
      this.delivery_options_errors = {};
      let order = this.order;
      this.delivery_companies = this.companies.filter((e) => {
        let paymentMethod = order.payment_method;
        if (!paymentMethod || paymentMethod.toLowerCase() === "cash") {
          return e.accept_cash === 1;
        } else if (["card", "wallet"].includes(paymentMethod.toLowerCase())) {
          return e.accept_card === 1;
        }
      });
      if (this.order) {
        this.$set(this.delivery_options_data, "id", this.order.id);
        this.$set(
          this.delivery_options_data,
          "delivery_company_id",
          this.order.delivery_company_id || this.delivery_companies.length
            ? this.delivery_companies.find((e) => e.main_name === "handesmade")
                ?.id
            : "" || ""
        );
        this.$set(
          this.delivery_options_data,
          "pack_id",
          this.order.pack_id || ""
        );
        this.$refs.delivery_options_modal.show();
      }
    },
    updateDeliveryOptions() {
      let index = this.orders.findIndex((e) => e.id == this.order.id);

      this.delivery_options_disabled = true;
      this.delivery_options_success = this.delivery_options_error = "";
      this.axios
        .post("orders/update_delivery_options", this.delivery_options_data)
        .then((response) => {
          let res = response.data;
          this.delivery_options_errors = {};
          if (res.error_flag == 0) {
            this.$set(
              this.orders[index],
              "delivery_company_id",
              res.result.order.delivery_company_id
            );
            this.$set(this.orders[index], "pack_id", res.result.order.pack_id);
            this.delivery_options_success = res.message;
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.delivery_options_errors = res.message;
            } else {
              this.delivery_options_error = res.message;
            }
          } else {
            this.handleError("updateDeliveryOptions", res.message);
          }
        })
        .catch((error) => {
          this.handleError("updateDeliveryOptions", error);
        })
        .finally(() => {
          this.delivery_options_disabled = false;
        });
    },
    downloadBill(id) {
      this.axios
        .get("orders/download_bill/" + id, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "invoice_" + id + ".pdf";
          link.click();
        })
        .catch((error) => {
          this.handleError("downloadBill", error);
        });
    },
  },
};
</script>
