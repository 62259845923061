<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <img
        src="/images/herfa_logo.png"
        alt="dashboard"
        class="brand-image img-circle elevation-3"
        style="opacity: .8"
      />
      <span class="brand-text font-weight-light">{{
        $store.state.app_name
      }}</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item" v-if="$can('show home')">
            <router-link to="/" class="nav-link" exact active-class="active">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("home") }}
              </p>
            </router-link>
          </li>

          <li class="nav-item" v-if="$can('show orders')">
            <router-link
              to="/live_orders"
              class="nav-link"
              active-class="active"
            >
              <i class="nav-icon fas fa-shopping-cart"></i>
              <p>
                {{ $t("live_orders") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show orders')">
            <router-link
              to="/past_orders"
              class="nav-link"
              active-class="active"
            >
              <i class="nav-icon fas fas fa-history"></i>
              <p>
                {{ $t("past_orders") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show orders')">
            <router-link
              to="/cancelled_orders"
              class="nav-link"
              active-class="active"
            >
              <i class="nav-icon fas fa-times"></i>
              <p>
                {{ $t("cancelled_orders") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show incomes')">
            <router-link to="/incomes" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-money-bill-wave"></i>
              <p>
                {{ $t("incomes") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show wallet')">
            <router-link to="/wallet" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-wallet"></i>
              <p>
                {{ $t("wallet") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show invoices')">
            <router-link to="/invoices" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-file-invoice"></i>
              <p>
                {{ $t("invoices") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show w_requests') && false">
            <router-link
              to="/w_requests"
              class="nav-link"
              active-class="active"
            >
              <i class="nav-icon fas fa-hand-holding-medical"></i>
              <p>
                {{ $t("w_requests") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show vendors')">
            <router-link to="/vendors" class="nav-link" active-class="active">
              <i class="nav-icon fa fa-users"></i>
              <p>
                {{ $t("vendors") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show stores')">
            <router-link to="/stores" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-store"></i>
              <p>
                {{ $t("stores") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show products')">
            <router-link to="/products" class="nav-link" active-class="active">
              <i class="nav-icon fab fa-shopify"></i>
              <p>
                {{ $t("products") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show collections') && false">
            <router-link
              to="/collections"
              class="nav-link"
              active-class="active"
            >
              <i class="nav-icon fas fa-layer-group"></i>
              <p>
                {{ $t("collections") }}
              </p>
            </router-link>
          </li>

          <li class="nav-item" v-if="$can('show ads')">
            <router-link to="/ads" class="nav-link" active-class="active">
              <i class="nav-icon fab fa-adversal"></i>
              <p>
                {{ $t("ads") }}
              </p>
            </router-link>
          </li>

          <li class="nav-item" v-if="$can('show coupons')">
            <router-link to="/coupons" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-shopping-bag"></i>
              <p>
                {{ $t("coupons") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show contacts')">
            <router-link to="/contacts" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-envelope"></i>
              <p>
                {{ $t("contacts") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show users')">
            <router-link to="/users" class="nav-link" active-class="active">
              <i class="nav-icon fa fa-users"></i>
              <p>
                {{ $t("users") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show admins')">
            <router-link to="/admins" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-user-shield"></i>
              <p>
                {{ $t("admins") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show notifications')">
            <router-link
              to="/notifications"
              class="nav-link"
              active-class="active"
            >
              <i class="nav-icon fas fa-bell"></i>
              <p>
                {{ $t("notifications") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show app_notifications')">
            <router-link
              to="/app-notifications"
              class="nav-link"
              active-class="active"
            >
              <i class="nav-icon fas fa-mobile"></i>
              <p>
                {{ $t("app_notifications") }}
              </p>
            </router-link>
          </li>

          <li
            v-if="isAdmin && $can('show settings')"
            :class="
              'nav-item has-treeview mt-2 ' +
                (c_tree == 1 ||
                [
                  '/settings',
                  '/email_templates',
                  '/notification_templates',
                  '/pages',
                  '/welcome_pages',
                  '/c_reasons',
                  '/help',
                  '/groups',
                  '/categories',
                  '/sub_categories',
                  '/options',
                  '/regions',
                  '/cities',
                  '/packs',
                  '/delivery_companies'
                ].includes($route.path)
                  ? 'menu-open'
                  : '')
            "
          >
            <a
              href="#"
              :class="
                'nav-link ' +
                  ([
                    '/settings',
                    '/email_templates',
                    '/notification_templates',
                    '/pages',
                    '/welcome_pages',
                    '/c_reasons',
                    '/help',
                    '/groups',
                    '/categories',
                    '/sub_categories',
                    '/options',
                    '/regions',
                    '/cities',
                    '/packs',
                    '/delivery_companies'
                  ].includes($route.path)
                    ? 'active'
                    : '')
              "
              @click="CTree(1)"
            >
              <i class="fa fa-cogs nav-icon"></i>
              <p>
                {{ $t("settings") }}
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" v-if="$can('show settings')">
                <router-link
                  to="/settings"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("general") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show settings')">
                <router-link
                  to="/email_templates"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("email_templates") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show settings')">
                <router-link
                  to="/notification_templates"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("notification_templates") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show pages')">
                <router-link to="/pages" class="nav-link" active-class="active">
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("pages") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show groups')">
                <router-link
                  to="/groups"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("groups") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show categories')">
                <router-link
                  to="/categories"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("categories") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show sub_categories')">
                <router-link
                  to="/sub_categories"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("sub_categories") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show p_groups')">
                <router-link
                  to="/p_groups"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("p_groups") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show options')">
                <router-link
                  to="/options"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("options") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show delivery_companies')">
                <router-link
                  to="/delivery_companies"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("delivery_companies") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show packs')">
                <router-link to="/packs" class="nav-link" active-class="active">
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("packs") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show welcome_pages')">
                <router-link
                  to="/welcome_pages"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("welcome_pages") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show c_reasons')">
                <router-link
                  to="/c_reasons"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("c_reasons") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show help')">
                <router-link to="/help" class="nav-link" active-class="active">
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("help") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/regions"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("regions") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/cities"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("cities") }}
                  </p>
                </router-link>
              </li>
            </ul>
            <ul
              class="nav nav-treeview"
              v-if="$can('show settings') && false && $can('show pages')"
            >
              <li class="nav-item" v-if="$can('show settings')">
                <router-link
                  to="/settings/info"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/info') ? 'active' : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("info") }}</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show settings') && false">
                <router-link
                  to="/settings/images"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/images') ? 'active' : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("images") }}</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show settings')">
                <router-link
                  to="/settings/slider"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/slider') ? 'active' : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("slider") }}</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show banners')">
                <router-link
                  to="/settings/banners"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/banners') ? 'active' : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("banners") }}</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show settings') && false">
                <router-link
                  to="/settings/payment_gates"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/payment_gates')
                        ? 'active'
                        : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("payment_gates") }}</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show pages')">
                <router-link
                  to="/settings/pages"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/pages') ? 'active' : '')
                  "
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>{{ $t("pages") }}</p>
                </router-link>
              </li>
            </ul>
          </li>
          <br />
          <br />
          <br />
          <br />
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      c_tree: "",
      options: {
        height: "100%",
        size: 7,
        color: "#fff"
      }
    };
  },
  methods: {
    CTree(i) {
      if (i == this.c_tree) {
        this.c_tree = "";
      } else {
        this.c_tree = i;
      }
    }
  }
};
</script>
