<template>
  <div>
    <b-modal
      ref="review_modal"
      :title="$t('review_2') + ' #' + review.id"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="col-md-12 contact_det">
          <div class="form-row" v-if="review.user">
            <div class="form-group col-md-12">
              <label>{{ $t("user") }}</label>

              {{ (review.user || {})["name_" + $i18n.locale] }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("rating") }}</label>
              {{ review.rating }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("comment") }}</label>
              <pre
                class="p-0 m-0"
              ><p style="font-family:cairo">{{ review.review_comment.trim()}}</p></pre>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("date") }}</label>
              {{
                $moment(review.created_at)
                  .tz($store.state.timezone)
                  .format("Y-MM-DD hh:mm A")
              }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="load-content" v-if="!load">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <div v-else>
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <h1>
                {{ product["name_" + $i18n.locale] || "..." }}
              </h1>
            </div>
          </div>
        </div>
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <b-card no-body>
                <b-card-body>
                  <form class="col-md-12">
                    <b-alert
                      :show="info_error || info_success ? true : false"
                      :variant="info_error ? 'danger' : 'success'"
                      dismissible
                      @dismissed="info_error = info_success = ''"
                    >
                      {{ info_error || info_success }}
                    </b-alert>

                    <div>
                      <b-tabs
                        content-class="mt-3"
                        no-key-nav
                        v-model="tab_index"
                      >
                        <b-tab
                          :title="$t('info')"
                          active
                          :title-link-class="
                            (Object.keys(info_errors) || []).some(e =>
                              [
                                'image_input',
                                'name_ar',
                                'name_en',
                                'desc_ar',
                                'desc_en',
                                'category_id',
                                'sku',
                                'qty',
                                'price',
                                'prepared_time'
                              ].includes(e)
                            )
                              ? 'invalid-tab'
                              : ''
                          "
                        >
                          <div class="form-row col-md-8">
                            <div class="form-group col-md-12">
                              <label>{{ $t("image") }}</label>
                              <div
                                class="image_div"
                                v-if="info_image || product.image"
                              >
                                <img
                                  :src="info_image || image_url + product.image"
                                  @error="
                                    $event.target.src =
                                      '/images/placeholder.jpg'
                                  "
                                />
                                <i
                                  v-if="info_image"
                                  class="fa fa-times"
                                  @click.prevent="
                                    info_image = null;
                                    info_icon = null;
                                  "
                                ></i>
                              </div>
                              <b-form-file
                                v-if="!info_image"
                                accept="image/*"
                                @change.prevent="onImageChange($event, 'info')"
                                ref="info_icon"
                              ></b-form-file>
                              <span class="error invalid-feedback d-block">{{
                                (info_errors.image_input || []).join("-")
                              }}</span>
                            </div>
                            <div class="form-group col-md-6">
                              <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                              <input
                                type="text"
                                class="form-control rtl"
                                :class="{
                                  'is-invalid': info_errors.name_ar
                                }"
                                v-model="product.name_ar"
                              />
                              <span class="error invalid-feedback">{{
                                (info_errors.name_ar || []).join("-")
                              }}</span>
                            </div>
                            <div class="form-group col-md-6">
                              <label>{{ $t("name") }} | {{ $t("en") }}</label>
                              <input
                                type="text"
                                class="form-control ltr"
                                :class="{
                                  'is-invalid': info_errors.name_en
                                }"
                                v-model="product.name_en"
                              />
                              <span class="error invalid-feedback">{{
                                (info_errors.name_en || []).join("-")
                              }}</span>
                            </div>
                            <div class="form-group col-md-6">
                              <label>{{ $t("desc") }} | {{ $t("ar") }}</label>
                              <textarea
                                class="form-control rtl"
                                :class="{
                                  'is-invalid': info_errors.desc_ar
                                }"
                                rows="4"
                                v-model="product.desc_ar"
                              ></textarea>
                              <span class="error invalid-feedback">{{
                                (info_errors.desc_ar || []).join("-")
                              }}</span>
                            </div>

                            <div class="form-group col-md-6">
                              <label>{{ $t("desc") }} | {{ $t("en") }}</label>
                              <textarea
                                class="form-control ltr"
                                :class="{
                                  'is-invalid': info_errors.desc_en
                                }"
                                rows="4"
                                v-model="product.desc_en"
                              ></textarea>
                              <span class="error invalid-feedback">{{
                                (info_errors.desc_en || []).join("-")
                              }}</span>
                            </div>
                            <div class="form-group col-md-12">
                              <div class="icheck-primary">
                                <input
                                  type="checkbox"
                                  name="special"
                                  id="special"
                                  v-model="product.is_special_order"
                                  :true-value="1"
                                  :false-value="0"
                                />
                                <label for="special">{{
                                  $t("special_order")
                                }}</label>
                              </div>
                            </div>

                            <div class="form-group col-md-12">
                              <label>{{ $t("categories") }}</label>
                              <multiselect
                                selectLabel=""
                                selectedLabel=""
                                deselectLabel=""
                                v-model="product.categories_ids"
                                :placeholder="
                                  $t('select') + ' ' + $t('categories')
                                "
                                :multiple="true"
                                :searchable="true"
                                :internal-search="true"
                                :options-limit="300"
                                :show-no-results="false"
                                :hide-selected="true"
                                :options="categories"
                                track-by="id"
                                :label="'name_' + $i18n.locale"
                                :custom-label="
                                  option => {
                                    return option['name_' + $i18n.locale];
                                  }
                                "
                              >
                              </multiselect>
                              <span class="error invalid-feedback d-block">{{
                                (info_errors.categories_ids || []).join("-")
                              }}</span>
                            </div>
                            <div
                              class="form-group col-md-12"
                              v-if="product.is_special_order === 0"
                            >
                              <label>{{ $t("sub_categories") }}</label>
                              <multiselect
                                selectLabel=""
                                selectedLabel=""
                                deselectLabel=""
                                v-model="product.sub_categories_ids"
                                :placeholder="
                                  $t('select') + ' ' + $t('sub_categories')
                                "
                                :multiple="true"
                                :searchable="true"
                                :internal-search="true"
                                :options-limit="300"
                                :show-no-results="false"
                                :hide-selected="true"
                                :group-select="true"
                                :options="sub_categories"
                                group-values="sub_categories"
                                group-label="category"
                                track-by="id"
                                :label="'name_' + $i18n.locale"
                                :custom-label="
                                  option => {
                                    return option['name_' + $i18n.locale];
                                  }
                                "
                              >
                              </multiselect>
                              <span class="error invalid-feedback d-block">{{
                                (info_errors.sub_categories_ids || []).join("-")
                              }}</span>
                            </div>
                            <div class="form-group col-md-6">
                              <label>{{ $t("sku") }}</label>
                              <input
                                type="text"
                                class="form-control ltr"
                                :class="{ 'is-invalid': info_errors.sku }"
                                v-model="product.sku"
                              />
                              <span class="error invalid-feedback">{{
                                (info_errors.sku || []).join("-")
                              }}</span>
                            </div>
                            <div class="form-group col-md-6">
                              <label>{{ $t("quantity") }}</label>
                              <input
                                type="number"
                                min="0"
                                class="form-control ltr"
                                :class="{ 'is-invalid': info_errors.qty }"
                                v-model="product.qty"
                                :disabled="product.is_unlimited === 1"
                                :readonly="product.is_unlimited === 1"
                              />
                              <div class="col-md-12 px-0 mt-2">
                                <div class="icheck-primary">
                                  <input
                                    type="checkbox"
                                    name="unlimited"
                                    id="unlimited"
                                    v-model="product.is_unlimited"
                                    :true-value="1"
                                    :false-value="0"
                                  />
                                  <label for="unlimited">{{
                                    $t("is_unlimited")
                                  }}</label>
                                </div>
                              </div>
                              <span class="error invalid-feedback">{{
                                (info_errors.qty || []).join("-")
                              }}</span>
                            </div>
                            <div
                              class="form-group col-md-6"
                              v-if="product.is_special_order === 0"
                            >
                              <label>{{ $t("price") }} ({{ $t("sar") }})</label>
                              <input
                                type="number"
                                min="0"
                                class="form-control ltr"
                                :class="{ 'is-invalid': info_errors.price }"
                                v-model="product.price"
                              />
                              <span class="error invalid-feedback">{{
                                (info_errors.price || []).join("-")
                              }}</span>
                            </div>

                            <div class="form-group col-md-6">
                              <label
                                >{{ $t("prepared_time") }} ({{
                                  $t("day")
                                }})</label
                              >
                              <input
                                type="number"
                                min="0"
                                class="form-control ltr"
                                :class="{
                                  'is-invalid': info_errors.prepared_time
                                }"
                                v-model="product.prepared_time"
                              />
                              <span class="error invalid-feedback">{{
                                (info_errors.prepared_time || []).join("-")
                              }}</span>
                            </div>
                          </div>
                        </b-tab>
                        <b-tab
                          :title="$t('options')"
                          :title-link-class="
                            (Object.keys(info_errors) || []).some(e =>
                              [].includes(e)
                            )
                              ? 'invalid-tab'
                              : ''
                          "
                        >
                          <div
                            class="col-md-8 text-center"
                            style="color:red;marginBottom:40px"
                            v-if="
                              product.categories_ids
                                ? product.categories_ids.length === 0
                                : false
                            "
                          >
                            {{ $t("firstly_select_category") }}
                          </div>
                          <div
                            class="col-md-8 text-center"
                            style="color:red;marginBottom:40px"
                            v-else-if="category_options.length == 0"
                          >
                            {{ $t("no_options_found") }}
                          </div>

                          <div class="form-row col-md-8" v-else>
                            <div
                              class="col-md-12"
                              v-for="(add_option, m) in product.options"
                              :key="m"
                            >
                              <div class="form-group col-12">
                                <label>{{ $t("image") }}</label>
                                <div
                                  class="image_div"
                                  v-if="product.options[m].image"
                                >
                                  <img
                                    :src="
                                      typeof product.options[m].image ===
                                      'object'
                                        ? convertImage(product.options[m].image)
                                        : image_url + product.options[m].image
                                    "
                                    @error="
                                      $event.target.src =
                                        '/images/placeholder.jpg'
                                    "
                                  />
                                  <i
                                    v-if="
                                      typeof product.options[m].image ===
                                        'object' &&
                                        convertImage(product.options[m].image)
                                    "
                                    class="fa fa-times"
                                    @click.prevent="
                                      product.options[m].image = null
                                    "
                                  ></i>
                                </div>
                                <b-form-file
                                  @change="
                                    product.options[m].image =
                                      $event.target.files[0]
                                  "
                                ></b-form-file>
                              </div>
                              <div class="input-group mb-3">
                                <select
                                  class="form-control pt-0"
                                  v-model="product.options[m].option_id"
                                  :class="{
                                    'is-invalid':
                                      info_errors[
                                        'options.' + m + '.option_value_id'
                                      ]
                                  }"
                                >
                                  <option disabled value="">{{
                                    $t("option")
                                  }}</option>
                                  <option
                                    v-for="(option, i) in category_options"
                                    :value="option.id"
                                    :key="i"
                                    >{{
                                      option["name_" + $i18n.locale]
                                    }}</option
                                  >
                                </select>
                                <select
                                  class="form-control pt-0"
                                  v-model="product.options[m].option_value_id"
                                  :class="{
                                    'is-invalid':
                                      info_errors[
                                        'options.' + m + '.option_value_id'
                                      ]
                                  }"
                                >
                                  <option disabled value="">{{
                                    $t("value")
                                  }}</option>
                                  <option
                                    v-for="(value, i) in (
                                      category_options.find(
                                        e =>
                                          e.id == product.options[m].option_id
                                      ) || {}
                                    ).values"
                                    :value="value.id"
                                    :key="i"
                                    >{{ value["name_" + $i18n.locale] }}</option
                                  >
                                </select>
                                <input
                                  type="number"
                                  min="0"
                                  class="form-control ltr"
                                  v-model="product.options[m].price"
                                  :class="{
                                    'is-invalid':
                                      info_errors['options.' + m + '.price']
                                  }"
                                  :placeholder="$t('price')"
                                />

                                <div class="input-group-append">
                                  <button
                                    class="btn btn-danger"
                                    @click="deleteOption(m)"
                                    type="button"
                                    v-tooltip="$t('delete_option')"
                                  >
                                    <i class="fa fa-times"></i>
                                  </button>
                                </div>
                                <span class="error invalid-feedback">{{
                                  (
                                    info_errors[
                                      "options." + m + ".option_value_id"
                                    ] || []
                                  ).join("-")
                                }}</span>
                                <span class="error invalid-feedback">{{
                                  (
                                    info_errors["options." + m + ".price"] || []
                                  ).join("-")
                                }}</span>
                              </div>
                            </div>
                            <button
                              class="btn btn-success btn-md"
                              v-if="product.options.length < options_max_count"
                              @click="addNewOption"
                              type="button"
                              v-tooltip="$t('add_new_option')"
                            >
                              {{ $t("add_new_option") }}
                              <i class="fa fa-plus mr-1 ml-1"></i>
                            </button>
                          </div>
                          <br />
                        </b-tab>
                        <b-tab
                          :title="$t('discount')"
                          :title-link-class="
                            (Object.keys(info_errors) || []).some(e =>
                              [
                                'discount_type',
                                'discount_value',
                                'discount_start',
                                'discount_end'
                              ].includes(e)
                            )
                              ? 'invalid-tab'
                              : ''
                          "
                        >
                          <div class="form-row col-md-8">
                            <div class="form-group col-md-12">
                              <label>{{ $t("discount_type") }}</label>
                              <select
                                class="form-control custom-select"
                                :class="{
                                  'is-invalid': info_errors.discount_type
                                }"
                                v-model="product.discount_type"
                              >
                                <option value="percentage">{{
                                  $t("percentage")
                                }}</option>
                                <option value="fixed">{{
                                  $t("fixed_value")
                                }}</option>
                              </select>
                              <span class="error invalid-feedback">{{
                                (info_errors.discount_type || []).join("-")
                              }}</span>
                            </div>

                            <div class="form-group col-md-12">
                              <label>{{ $t("discount_value") }}</label>
                              <input
                                type="number"
                                min="0"
                                :max="
                                  product.discount_type == 'percentage'
                                    ? 99
                                    : product.price
                                "
                                class="form-control ltr"
                                :class="{
                                  'is-invalid': info_errors.discount_value
                                }"
                                v-model="product.discount_value"
                              />
                              <span class="error invalid-feedback">{{
                                (info_errors.discount_value || []).join("-")
                              }}</span>
                            </div>
                            <div class="form-group col-md-6">
                              <label>{{ $t("discount_start") }}</label>
                              <input
                                type="date"
                                class="form-control"
                                :class="{
                                  'is-invalid': info_errors.discount_start
                                }"
                                v-model="product.discount_start"
                              />
                              <span class="error invalid-feedback">{{
                                (info_errors.discount_start || []).join("-")
                              }}</span>
                            </div>
                            <div class="form-group col-md-6">
                              <label>{{ $t("discount_end") }}</label>
                              <input
                                type="date"
                                class="form-control"
                                :class="{
                                  'is-invalid': info_errors.discount_end
                                }"
                                v-model="product.discount_end"
                              />
                              <span class="error invalid-feedback">{{
                                (info_errors.discount_end || []).join("-")
                              }}</span>
                            </div>
                          </div>
                        </b-tab>

                        <b-tab :title="$t('images')">
                          <div class="form-row col-md-8">
                            <div class="form-group new-input col-md-12">
                              <label>{{ $t("images") }}</label>

                              <div class="col-md-12">
                                <div
                                  class="img_div"
                                  v-for="(img, i) in product.images"
                                  :key="i"
                                >
                                  <img :src="image_url + img.image" />
                                  <div
                                    @click.prevent="deleteImage(img.id)"
                                    v-if="!disabled"
                                  >
                                    <i class="fa fa-times"></i>
                                  </div>
                                </div>
                              </div>

                              <b-form-file
                                @change.prevent="upImages"
                                multiple
                                v-model="images_input"
                                :file-name-formatter="formatNames"
                                accept="image/*"
                              ></b-form-file>
                            </div>

                            <div class="col-md-12">
                              <div
                                class="img_div"
                                v-for="(img, i) in images_arr"
                                :key="i"
                              >
                                <img :src="img.image" />
                                <div
                                  @click.prevent="removeImg(i)"
                                  v-if="!disabled"
                                >
                                  <i class="fa fa-times"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-tab>
                        <b-tab :title="$t('reviews')">
                          <template slot="title">
                            {{ $t("reviews") }} [ {{ rows }} ]
                          </template>
                          <div class="col-md-12">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th
                                    class="sort border-top-0"
                                    @click="kimoSort(reviews, 'id')"
                                  >
                                    #
                                  </th>
                                  <th
                                    class="sort border-top-0"
                                    @click="kimoSort(reviews, 'user_id')"
                                  >
                                    {{ $t("user") }}
                                  </th>
                                  <th
                                    class="sort border-top-0"
                                    @click="kimoSort(reviews, 'rating')"
                                  >
                                    {{ $t("rating") }}
                                  </th>
                                  <th
                                    class="sort border-top-0"
                                    @click="kimoSort(reviews, 'is_read')"
                                  >
                                    {{ $t("status") }}
                                  </th>

                                  <th
                                    class="sort border-top-0"
                                    @click="kimoSort(reviews, 'created_at')"
                                  >
                                    {{ $t("created_at") }}
                                  </th>
                                  <th class="border-top-0">
                                    {{ $t("actions") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colspan="7" v-if="!load">
                                    <i class="fa fa-sync  fa-spin load-con"></i>
                                  </td>
                                  <td
                                    colspan="7"
                                    class="no_data"
                                    v-if="load && reviews.length == 0"
                                  >
                                    {{ $t("no_data") }}
                                  </td>
                                </tr>
                                <tr
                                  v-for="(item, i) in reviews"
                                  :key="i"
                                  v-show="load"
                                >
                                  <td>{{ item.id }}</td>

                                  <td>
                                    {{
                                      (item.user || {})["name_" + $i18n.locale]
                                    }}
                                  </td>
                                  <td>
                                    {{ item.rating }}
                                  </td>
                                  <td class="pb-0">
                                    <label class="switch mr-2 ml-2">
                                      <input
                                        type="checkbox"
                                        :disabled="!$can('edit products')"
                                        v-model="item.status"
                                        :true-value="'on'"
                                        :false-value="'off'"
                                        @change="
                                          $can('edit products')
                                            ? changeReviewStatus(item.id)
                                            : false
                                        "
                                      />
                                      <div class="slider round">
                                        <span class="on">{{ $t("on") }}</span>
                                        <span class="off">{{ $t("off") }}</span>
                                      </div>
                                    </label>
                                  </td>

                                  <td>
                                    {{
                                      $moment(item.created_at)
                                        .tz($store.state.timezone)
                                        .format("Y-MM-DD hh:mm A")
                                    }}
                                  </td>
                                  <td class="actions-group">
                                    <button
                                      v-if="$can('show products')"
                                      class="btn btn-info btn-sm"
                                      v-tooltip="$t('show')"
                                      @click.prevent="showReview(item.id)"
                                    >
                                      <i class="fas fa-eye"> </i>
                                    </button>
                                    <button
                                      v-if="$can('delete products')"
                                      @click.prevent="deleteReview(item.id)"
                                      class="btn btn-danger btn-sm"
                                      v-tooltip="$t('delete')"
                                    >
                                      <i class="fas fa-trash"> </i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <b-pagination
                              class="m-2"
                              v-model="currentPage"
                              :total-rows="rows"
                              :per-page="perPage"
                              :prev-text="$t('previous')"
                              :next-text="$t('next')"
                              align="right"
                              :first-number="true"
                              :last-number="true"
                            ></b-pagination>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>

                    <button
                      v-if="tab_index != 3"
                      type="submit"
                      class="btn btn-info mt-2"
                      :disabled="info_disabled"
                      @click.prevent="updateProduct"
                    >
                      {{ $t("save") }}
                      <b-spinner small v-if="info_disabled"></b-spinner>
                    </button>
                  </form>
                </b-card-body>
                <!-- /.card-body -->
              </b-card>

              <!-- /.card -->
            </div>
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "Product",
  components: {
    Multiselect
  },
  data() {
    return {
      load: false,
      info_disabled: false,
      info_success: "",
      info_error: "",
      info_errors: {},
      product_icon: null,
      product_image: null,
      info_icon: null,
      info_image: null,
      image_url: "",
      categories: [],
      sub_categories: [],
      images_input: [],
      images_arr: [],
      disabled: false,
      id: "",
      reviews: [],
      review: {},
      currentPage: 1,
      perPage: 1,
      rows: 0,
      tab_index: 0,
      dimensions: [],
      options: [],
      category_options: [],
      product: {
        sub_categories_id: []
      }
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getOptions().then(() => {
      this.getProduct();
    });
    this.getCategories();
    this.getReviews();
    //this.getDimensions();
  },
  watch: {
    currentPage() {
      return this.getReviews();
    },
    "product.categories_ids"() {
      this.sub_categories = [];
      if (this.product.categories_ids) {
        if (this.product.categories_ids.length > 0) {
          this.product.categories_ids.forEach(e => {
            // if (!this.sub_categories.find(m => m.id == e.id)) {
            this.sub_categories.push({
              id: e.id,
              category: e["name_" + this.$i18n.locale],
              sub_categories: e.sub_categories
            });
            //}
          });
          this.$set(
            this.product,
            "sub_categories_ids",
            (this.product.sub_categories_ids || []).filter(value => {
              return this.product.categories_ids
                .map(e => e.id)
                .includes(value.category_id);
            })
          );
        } else {
          this.sub_categories = [];
          this.$set(this.product, "sub_categories_ids", []);
        }

        this.category_options = this.options.filter(e => {
          let item =
            e.category_id ==
            this.product.categories_ids.find(c => c.id === e.category_id)?.id;
          return {
            ...item
          };
        });
        this.options_max_count = this.category_options
          .map(e => e.values.length)
          .reduce(function(a, b) {
            return a + b;
          }, 0);
        // if (this.category_options.length > 0) {
        //   this.$set(this.product, "options", [
        //     {
        //       option_id: "",
        //       option_value_id: "",
        //       price: "",
        //       image: null
        //     }
        //   ]);
        // } else {
        //   this.$set(this.product, "options", []);
        // }
      }
    },
    "product.name_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.product, "name_ar", "");
      }
    },
    "product.desc_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.product, "desc_ar", "");
      }
    }
  },
  methods: {
    getProduct() {
      return this.axios
        .get("products/" + this.id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.product = res.result.product;
            this.$set(this.product, "categories_ids", this.product.categories);
            this.$nextTick(() => {
              this.$set(
                this.product,
                "sub_categories_ids",
                this.product.sub_categories
              );
            });
            this.$set(
              this.product,
              "is_special_order",
              Number(this.product.price) === 0 ? 1 : 0
            );
            this.$set(
              this.product,
              "is_unlimited",
              Number(this.product.qty) === 0 ? 1 : 0
            );
            this.category_options = this.options.filter(
              e => e.category_id == this.product.category_id
            );
            this.image_url = res.result.image_url;
          } else {
            this.$router.push("/products");
          }
        })
        .catch(error => {
          this.handleError("getProduct", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    getDimensions() {
      return this.axios
        .get("dimensions")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.dimensions = res.result.dimensions.data;
          }
        })
        .catch(error => {
          this.handleError("getDimensions", error);
        });
    },
    getReviews() {
      return this.axios
        .get("products/reviews/" + this.id, {
          page: this.currentPage
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.reviews = res.result.reviews.data;
            this.rows = res.result.reviews.total;
            this.perPage = res.result.reviews.per_page;
          } else {
            this.alert(res.message, this.$t("error"));
          }
        })
        .catch(error => {
          this.handleError("getReviews", error);
        });
    },
    getCategories() {
      return this.axios
        .get("categories")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.categories = res.result.categories;
          }
        })
        .catch(error => {
          this.handleError("getCategories", error);
        });
    },
    getOptions() {
      return this.axios.get("options").then(response => {
        let res = response.data;
        if (res.error_flag == 0) {
          this.options = res.result.options;
        }
      });
    },
    updateProduct() {
      this.info_disabled = true;
      let fd = new FormData();

      if (this.info_icon) {
        fd.append("image_input", this.info_icon, this.info_icon.name);
      }
      Object.keys(this.product).forEach(key => {
        if (this.product[key] || this.product[key] === 0) {
          if (key == "options") {
            this.product[key].forEach((e, i) => {
              fd.append(
                key + "[" + i + "][option_value_id]",
                e.option_value_id
              );
              fd.append(key + "[" + i + "][price]", e.price);
              if (e.image && typeof e.image === "object") {
                fd.append(key + "[" + i + "][image]", e.image, e.image.name);
              }
            });
          } else if (
            ["sub_categories_ids", "price", "categories_ids"].includes(key)
          ) {
            if (this.product.is_special_order === 1) {
              if (["sub_categories_ids", "price"].includes(key)) {
                fd.delete(key);
              }
            }
            if (Array.isArray(this.product[key])) {
              this.product[key].forEach((cat, idx) => {
                fd.append(`${key}[${idx}]`, cat.id);
              });
            } else {
              fd.append(key, this.product[key]);
            }
            console.log(this.product[key]);
          } else if (key === "qty") {
            if (this.product.is_unlimited === 1) {
              fd.delete(key, "");
            }
            fd.append(key, this.product[key]);
          } else {
            // if (!fd.has(key)) {
            fd.append(key, this.product[key]);
            // }
          }
        }
      });

      if (this.images_input.length > 0) {
        this.images_input.forEach((e, i) => {
          if (this.isImage(e)) {
            fd.append("images_input[" + i + "]", e, e.name);
          }
        });
      }

      this.info_error = this.info_success = "";
      this.axios
        .post("products/update", fd)
        .then(response => {
          this.info_errors = {};
          let res = response.data;
          if (res.error_flag == 0) {
            this.info_success = res.message;
            this.info_image = null;
            this.info_icon = null;
            this.images_input = [];
            this.images_arr = [];
            this.product = res.result.product;
            this.$set(this.product, "categories_ids", this.product.categories);
            this.$nextTick(() => {
              this.$set(
                this.product,
                "sub_categories_ids",
                this.product.sub_categories
              );
            });
          } else if (res.error_flag == 1) {
            typeof res.message === "object"
              ? (this.info_errors = res.message)
              : (this.info_error = res.message);
          } else {
            this.handleError("updateProduct", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateProduct", error);
        })
        .finally(() => {
          this.info_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    deleteImage(id) {
      let index = this.findIndex(this.product.images, "id", id);
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("products/delete_image/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.product.images.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteImage", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteImage", error);
              });
          }
        });
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} files selected`;
    },
    upImages(evt) {
      evt.target.files.forEach(e => {
        if (this.isImage(e)) {
          this.toBase64(e).then(res => {
            this.images_arr.push({ name: e.name, image: res });
          });
        }
      });
    },
    removeImg(i) {
      this.images_input.splice(i, 1);
      this.images_arr.splice(i, 1);
    },
    showReview(id) {
      this.review = this.reviews.find(e => e.id == id);
      this.$refs.review_modal.show();
    },
    changeReviewStatus(id) {
      let index = this.findIndex(this.reviews, "id", id);
      this.axios
        .post("products/reviews/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.reviews[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeReviewStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("changeReviewStatus", error);
        });
    },
    deleteReview(id) {
      let index = this.findIndex(this.reviews, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("products/reviews/delete_review/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.reviews.splice(index, 1);
                  this.rows -= 1;
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteReview", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteReview", error);
              });
          }
        });
    },
    addNewOption() {
      this.product.options.push({
        option_id: "",
        option_value_id: "",
        price: ""
      });
    },
    deleteOption(index) {
      this.product.options.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
